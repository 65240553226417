import React, { useEffect } from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useAnimation, motion } from 'framer-motion'
import environment from '../../Environment/Environment'
export default function Footer() {
    const { ref, inView } = useInView({
        threshold: .2
    });

    const animation = useAnimation();
    const frameAnim = useAnimation();
    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: .5,
                    ease: "easeIn",

                }
            })
            frameAnim.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: .5,
                    ease: "easeIn",
                    delay: .4

                }
            })
        }
        else {
            animation.start({
                y: 100,
                opacity: 0
            })
            frameAnim.start({
                y: 10,
                opacity: 0
            })
        }
    }, [inView]);
    return (
        <div className='footer'>
            <div ref={ref} className="fsection">
                <Link to='/home' className='fsection-ilink'>
                    <motion.img src={environment.mediaUrl+"/logo.svg"} alt="" animate={animation} className='logo' />
                </Link>
                <motion.div animate={animation} className="fInfo">
                    <div className="footercontact">
                        <h3 className='cHead'>Connect with Us</h3>
                        <div className="cIcon">
                            <a href="https://www.facebook.com/pennonn" target="_blank"><img src={environment.mediaUrl + "/contactIcons/facebook.svg"} alt="" /></a>
                            <a href="https://twitter.com/pennonn?s=08" target="_blank"><img src={environment.mediaUrl + "/contactIcons/twitter.svg"} alt="" /></a>
                            <a href="https://www.instagram.com/pennonn_official/" target="_blank"><img src={environment.mediaUrl + "/contactIcons/insta.svg"} alt="" /></a>
                            <a href="https://www.whatsapp.com/" target="_blank"><img src={environment.mediaUrl + "/contactIcons/whatsapp.svg"} alt="" /></a>
                        </div>
                        <div className=" cNo">
                            <img src={environment.mediaUrl + "/contactIcons/phone.svg"} alt="" />
                            <p><a href="tel:+19736525023">(973) 652-5023</a></p>
                            <img src={environment.mediaUrl + "/contactIcons/mail.svg"} alt="" />
                            <p><a href="mailto:info@pennonn.com">info@pennonn.com</a></p>
                        </div>
                    </div>
                    <div className="quicklink">
                        <h3>Quick Links</h3>
                        <div className="groupLink">
                            <Link to="/services"><p>Services</p></Link>
                            <Link to="/careers"><p>Careers</p></Link>
                            <p>Insights</p>
                            <p>Industries</p>
                            <p>Blog</p>
                            <p>Our Vision</p>
                            <p>Solution</p>
                            <p>News</p>
                            <Link to="/contactus"><p>Contact Us</p></Link>
                            <Link to="/about"><p>About</p></Link>
                            <p>Our Values</p>
                            <Link to="/projects"><p>Case Studies</p></Link>
                        </div>
                    </div>
                    <div className="location">
                        <h3>Visit our Office at</h3>
                        <div className="laddres">
                            <p className='head'>Head Office</p>
                            <p> 24710 
                                Cobble Canyon Lane,
                                Katy, Texas, 77494</p>
                            <p className='head'>Office</p>
                            <p>Pennonn, 2nd floor, Siyad Tower, Azad Road, Kaloor, Ernakulam,
                                Kerala, India - 670326</p>
                        </div>
                    </div>
                </motion.div>
                <motion.div ref={ref} animate={frameAnim}>
                    <hr className='footerLine' />
                    <div className="copyright">
                        <p>Copyright © {(new Date().getFullYear())}, All Rights Reserved Pennonn.Inc</p>
                        <p>Policies Terms & Conditions</p>
                    </div>
                </motion.div>
            </div>
        </div >
    )
}
