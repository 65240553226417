import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import environment from '../../../Environment/Environment'
import '../Home.scss'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

export default function Projects() {
    const hover = {

        backgroundPosition: "right"
    }
    const transition = {
        duration: .2,
        ease: "easeIn"
    }
    const { ref, inView } = useInView();
    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: .5,
                    ease: "easeIn",

                }
            })
        }
        else {
            animation.start({
                y: 100,
                opacity: 0
            })
        }
    }, [inView]);
    const animation = useAnimation();
  
    return (
            <>
                <motion.div animate={animation} ref={ref} className="abouttexts">
                    <p className='head'>CASE STUDIES</p>
                    <h3>Well Care - create innovative and creative digital products.</h3>
                    <p>Wellcare is a UK based company needs an online
                        solution for marketing their
                        specialized nursing care service across the Europe. Create a
                        fast-loading web application. A cross-platform progressive Web application that
                        provides users with enhanced mobile experience.</p>
                    <Link to="/projects"><motion.button whileHover={hover}
                        transition={transition}
                        className='button'>View more</motion.button></Link>
                </motion.div>
                <img src={environment.mediaUrl+"/home/projectImg.png"} alt="" />
            </>
    )
}
