import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import "../Home.scss";
import { useInView } from "react-intersection-observer";
import environment from "../../../Environment/Environment";
export default function Industries() {
  const hover = {
    backgroundPosition: "right",
  };
  const transition = {
    duration: 0.2,
    ease: "easeIn",
  };
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeIn",
        },
      });
    } else {
      animation.start({
        y: 100,
        opacity: 0,
      });
    }
  }, [inView]);
  const animation = useAnimation();

  return (
    <div className="aboutSection">
      <motion.div ref={ref} animate={animation} className="abouttexts">
        <p className="head">INDUSTRIES</p>
        <h3>We create innovative and creative digital products.</h3>
        <p>
          Pennonn is an emerging Software Development company based in Texas
          and founded by a group of experienced IT professionals in 2020. We
          serve globally. We create innovative web and mobile applications that
          enable our customers to compete and win.
        </p>
        <Link to="/industries">
          <motion.button
            whileHover={hover}
            transition={transition}
            className="button"
          >
            View All
          </motion.button>
        </Link>
      </motion.div>
      <img src={environment.mediaUrl + "/home/industries.jpg"} alt="" />
    </div>
  );
}
