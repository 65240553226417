import React, { useEffect } from 'react'
import { Button, Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import './Home.scss'
import About from './Section/About'
import Projects from './Section/Projects'
import Testimonial from './Section/Testimonial'
import Industries from './Section/Industries'
import GetinTouch from './Section/GetinTouch'
import ServiceSection from './Section/ServiceSection'
import { useState } from 'react'
import environment from '../../Environment/Environment'
import MetaDecorator from '../../utils/MetaTag/MetaDecorator'
import tags from '../../utils/MetaTag/MetaTags'
export default function Home() {
    const init = {
        y: 100,
        opacity: 0
    }
    const anim = {
        y: 0,
        opacity: 1
    }
    const transit = {
        duration: .8,
        ease: "easeIn"
    }
    const transitbtn = {
        duration: .8,
        ease: "easeIn",
        delay: 1
    }
    const [width, setwidth] = useState()
    var p = document.getElementById("project");
    useEffect(() => {
        var p = document.getElementById("project");
        var style = p.currentStyle || window.getComputedStyle(p);
        setwidth(style.marginRight)
    }, [])
    return (
        <div className="homeBody">
            <MetaDecorator title={tags.home.title} description={tags.home.description} keywords={tags.home.keywords} />
            <div className='homeCarousel'>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={environment.mediaUrl + "/heroSection/carouselImg1.jpg"}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <motion.h3 initial={init} animate={anim} transition={transit} >Digital Transformation</motion.h3>
                            <motion.h2 initial={init} animate={anim} transition={transit}>FOR EFFICIENT RESULTS</motion.h2>
                            {/* <motion.div className="btns" initial={init} animate={anim} transition={transitbtn}>
                                <Link to="/services"><Button variant="outline-light" className='button light'>Know More</Button></Link>
                                <Link to="/about"><Button variant="outline-light" className='button orange'>About Us</Button></Link>
                            </motion.div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={environment.mediaUrl + "/heroSection/carouselImg2.jpg"}
                            alt="Second slide"
                        />
                        <Carousel.Caption>
                            <h3>High-end User Experience</h3>
                            <h2>FOR BUSINESS PROCESS</h2>
                            {/* <div className="btns">
                                <Link to="/services"><Button variant="outline-light" className='button light'>Know More</Button></Link>
                                <Link to="/about"><Button variant="outline-light" className='button orange'>About Us</Button></Link>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={environment.mediaUrl + "/heroSection/carouselImg3.jpg"}
                            alt="Third slide"
                        />
                        <Carousel.Caption>
                            <h3>Infrastructure Automation</h3>
                            <h2>FOR DIGITAL TRANSFORMATION</h2>
                            {/* <div className="btns">
                                <Link to="/services"><Button variant="outline-light" className='button light'>Know More</Button></Link>
                                <Link to="/about"><Button variant="outline-light" className='button orange'>About Us</Button></Link>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={environment.mediaUrl + "/heroSection/carouselImg4.jpg"}
                            alt="Fourth slide"
                        />
                        <Carousel.Caption>
                            <h3>Data Insights</h3>
                            <h2>FOR BUSINESS DECISIONS</h2>
                            {/* <div className="btns">
                                <Link to="/services"><Button variant="outline-light" className='button light'>Know More</Button></Link>
                                <Link to="/about"><Button variant="outline-light" className='button orange'>About Us</Button></Link>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={environment.mediaUrl + "/heroSection/carouselImg5.jpg"}
                            alt="Fifth slide"
                        />
                        <Carousel.Caption>
                            <h3>CTO as a Service</h3>
                            <h2>FOR STARTUPS</h2>
                            {/* <div className="btns">
                                <Link to="/services"><Button variant="outline-light" className='button light'>Know More</Button></Link>
                                <Link to="/about"><Button variant="outline-light" className='button orange'>About Us</Button></Link>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <About />
            <ServiceSection />
            <div className="project" id='project' style={{ margin: "2rem auto" }} >
                <Projects />
            </div>
            <Testimonial width={width} />
            <Industries />
            <GetinTouch />
        </div>
    )
}
