import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion'
import '../Home.scss'
import { useInView } from 'react-intersection-observer'
import environment from '../../../Environment/Environment'
export default function ServiceSection() {
    const hover = {
        backgroundPosition: "right"
    }
    const transition = {
        duration: .2,
        ease: "easeIn"
    }
    const item = {
        hidden: {
            opacity: 0,
            y: 100
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .5,
            }
        }
    }
    const itemchild = {
        hidden: {
            opacity: 0,
            y: 100
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .5,
            }
        }
    }
    const { ref, inView } = useInView();
    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: .5,
                    ease: "easeIn",

                }
            })
        }
        else {
            animation.start({
                y: 100,
                opacity: 0
            })
        }
    }, [inView]);
    const animation = useAnimation();
    return (
        <div className="serviceSection">
            <motion.div ref={ref} animate={animation} className="section">
                <motion.div initial="hidden" animate="show" className="abouttexts">

                    <p className='head'>OUR SERVICES</p>
                    <h3>We offer a wide variety of IT Services</h3>
                    <p>Pennonn offers a wide range of services such as Product Engineering,
                        DevOps Transformation, Agile Transformation,
                        Cloud Computing and Cloud Migration and CTO As A Service.
                    </p>
                    <Link to="/services"> <motion.button whileHover={hover}
                        transition={transition}
                        className='button'>READ MORE</motion.button></Link>
                </motion.div>
                <motion.div variant={item} className="svcIcons">
                    <motion.div variant={itemchild} className="Igroup">
                        <img src={environment.mediaUrl + "/serviceIcons/ProductEngIcon.svg"} alt="" />
                        <p>Product Engineering</p>
                    </motion.div>
                    <motion.div variant={itemchild} className="Igroup">
                        <img src={environment.mediaUrl + "/serviceIcons/CloudEngIcon.svg"} alt="" />
                        <p>Cloud Engineering</p>
                    </motion.div>
                    <motion.div variant={itemchild} className="Igroup">
                        <img src={environment.mediaUrl + "/serviceIcons/digitalMarketingIcon.svg"} alt="" />
                        <p>Digital Marketing</p>
                    </motion.div>
                    <motion.div variant={itemchild} className="Igroup">
                        <img src={environment.mediaUrl + "/serviceIcons/devopIcon.svg"} alt="" />
                        <p>Devops Transformation</p>
                    </motion.div>
                    <motion.div variant={itemchild} className="Igroup">
                        <img src={environment.mediaUrl + "/serviceIcons/agileIcon.svg"} alt="" />
                        <p>Agile Transformation</p>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    )
}
