import React, { useState, useEffect } from 'react'
import { Accordion, NavDropdown } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import environment from '../../Environment/Environment'
import './Header.scss'
export default function Header() {
    const [show, setShow] = useState(false)
    var scrollValue = 0;

    const handleScroll = () => {
        if (document.body.getBoundingClientRect().top < scrollValue) {
            setShow(true)
        }
        else if (document.body.getBoundingClientRect().top >= scrollValue) {
            setShow(false)
        }
        scrollValue = document.body.getBoundingClientRect().top
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    const [state, setstate] = useState(false)

    var headVariant = {
        start: {
            opacity: 0,
            y: -100
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .8,
                ease: "easeIn"
            }
        }

    }
    return (
        <div className={show ? `headhide` : "headshow"}>
            <motion.div className="header"
                variants={headVariant} animate="show" initial="start" exit="start" >
                <div className="headSection" >
                    <Link to="/"><motion.img whileHover={{ opacity: .6 }}
                        transition={{ duration: .2, ease: "easeIn" }} src={environment.mediaUrl+"/logo.svg"} alt="" className='logoImg' /></Link>
                    <ul>
                        <div className="links">
                            <NavLink to="/home" activeclassname="active"><li>Home</li></NavLink>
                            <NavLink to="/about" activeclassname="active"><li>About Us</li></NavLink>
                            <NavLink to="/services" activeclassname="active"><li>Services</li></NavLink>
                            {/* <NavLink to="/industries" activeclassname="active"><li>Industries</li></NavLink> */}
                            <NavLink to="/projects" activeclassname="active"><li>Case Studies</li></NavLink>
                            <Link to="/contactus"><motion.button
                                whileHover={{ backgroundPosition: "right" }}
                                transition={{ duration: .2, ease: "easeIn" }}
                                className='cBtn'>GET IN TOUCH</motion.button></Link>
                            <div id="menuToggle">
                                <input type="checkbox" checked={state} onChange={() => { setstate(!state) }} />
                                <span></span>
                                <span></span>
                                <span></span>
                                <ul id="menu">
                                    <NavLink to="/home" className='collapseMenu' activeclassname="active" onClick={() => { setstate(false) }}><li>Home</li></NavLink>
                                    <NavLink to="/about" activeclassname="active" className='collapseMenu' onClick={() => { setstate(false) }}><li>About</li></NavLink>
                                    <NavLink to="/services" activeclassname="active" className='collapseMenu' onClick={() => { setstate(false) }}><li>Services</li></NavLink>
                                    {/* <NavLink to="/industries" activeclassname="active" className='collapseMenu' onClick={() => { setstate(false) }}><li>Industries</li></NavLink> */}
                                    <NavLink to="/projects" activeclassname="active" className='collapseMenu' onClick={() => { setstate(false) }}><li>Case Studies</li></NavLink>
                                    <NavLink to="/contactus" activeclassname="active" className='collapseMenu' onClick={() => { setstate(false) }}><li>Contact Us</li></NavLink>
                                </ul>
                            </div>
                        </div>
                    </ul>
                </div>
            </motion.div >
        </div>
    )
}
