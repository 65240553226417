import React from 'react';
import { Link } from 'react-router-dom';

export default function Sitemap() {
    return <div className='mt-5 py-5'>
        <h1 >Sitemap</h1>
        <ul>
            <li>
                <Link to="/home">Home</Link>
            </li>
            <li>
                <Link to="/contactus">Contact Us</Link>
            </li>
            <li>
                <Link to="/services">Services</Link>
            </li>
            <li>

                <Link to="/about">About</Link>
            </li>
            <li>
                <Link to="/careers">Careers</Link>
            </li>
            <li>
                <Link to="/projects">Projects</Link>
            </li>
            <li>
                <Link to="/industries">Industries</Link>
            </li>
            <li>
                <Link to="/testimonial">Testimonial</Link>
            </li>
            <li>
                <Link to="/projects/casestudies">Case Studies</Link>
            </li>
        </ul>
    </div>;
}
