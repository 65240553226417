const tags = {
    home: {
        title: "Pennonn | The Way Makers",
        description: "Pennonn is a Software Development Company offering digital services, application maintenance, digital transformation, cloud computing. With the vast and growing digital capabilities, See how Pennonn can transform digital work for your business.",
        keywords: "Pennonn, Pennonn.com, Penon, Pennon, software companies in kochi, software company kochi, it company kochi, it company in kochi, kaloor it companies,job openings in kochi, job openings in kaloor,  Business Solutions, IT Services, digital transformation, Cloud Computing, AWS, Product Engineering, Cloud, Cloud Services, customer experience, digital manufacturing, digital, digital business"
    },
    about: {
        title: "About Us | Pennonn",
        description: "Explore information about Pennonn, who we are, what are we remembered for, our values, our insights, our vision, why are clients important to us.",
        keywords: "About Pennonn, Pennonn values, Pennonn insights, What is Pennonn, why Pennonn, what do we do"
    },
    services: {
        title: "Services | Pennonn",
        description: "Pennonn offers a wide range of software services to Business and Industries such as Product Engineering, DeVops Transformation, Cloud and Platform Engineering, App Transformation, Security Transformation, Digital Marketing, Agile Transformation, CTOaS- CTO As a Service",
        keywords: "Pennonn Solutions, Pennonn Services, Product Engineering, Cloud and Platform Engineering, Digital Transformation, DeVops Transformation, Software Development, App Development, Security Transformation, App Transformation, Cloud Transformation,Agile Transformation, CTOaS- CTO As a Service."
    },
    industries: {
        title: "Industries | Pennonn",
        description: "Pennonn offers a wide range of software solutions to Business and Industries such as Retail, Logistics, Manufacturing, Real estate and many more.",
        keywords: "Pennonn Solutions, Pennonn Industries, Pennonn Business, Retail, Logistics, Manufacturing, Real Estate, Software Development, App Development, Online Shopping, ecommerce"
    },
    casestudy: {
        title: "Case Study | Pennonn",
        description: "Pennonn offers a wide range of software solutions to Business and Industries such as Retail, Logistics, Manufacturing, Real estate and many more.",
        keywords: "Pennonn Case Study, Pennonn Projects, projects, case study"
    },
    contact: {
        title: "Contact Us | Pennonn",
        description: "Pennonn offers a wide range of software solutions to Business and Industries such as Retail, Logistics, Manufacturing, Real estate and many more.",
        keywords: "Pennonn Contact Us, Contact Pennonn, Pennon contact, Pennonn Contact"
    },


}
export default tags;