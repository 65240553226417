import React, { useEffect, useRef, useState } from "react";
import "./Contact.scss";
import { motion } from "framer-motion";
import axios from "axios";
import Validate from "../../utils/Validation/Validation";
import MetaDecorator from "../../utils/MetaTag/MetaDecorator";
import tags from "../../utils/MetaTag/MetaTags";
import emailjs from "@emailjs/browser";

export default function Form() {
  const [values, setvalues] = useState({
    checkbox: false,
  });
  const [errors, setErrors] = useState({});
  const [submitted, setsubmitted] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const [user, setUser] = useState({
    fields: {
      FullName: { value: "", required: true },
      Organisation: { value: "", required: true },
      ContactNo: {
        value: "",
        required: true,
        validate: { pattern: /^\d{10}$/ },
      },
      Email: {
        value: "",
        required: true,
        validate: { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ },
      },
      Country: { value: "", required: true },
      Description: { value: "", required: true },
    },
  });
  const [checked, setchecked] = useState(false);
  function handleChange(e) {
    const { name, value } = e.target;
    setUser({
      ...user,
      fields: {
        ...user.fields,
        [name]: { ...user.fields[name], value: value },
      },
    });
    setvalues({ ...values, [name]: value });
  }
  function handleSubmit(e) {
    e.preventDefault();
    setErrors(Validate(user));
    setIsvalid(true);
  }
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // console.log("form.current", form.current);
    emailjs
      .sendForm(
        "service_xsw9yvz",
        "template_67lutwp",
        form.current,
        "hhznS2TmlfT19Oo03"
      )
      .then(
        (result) => {
          setsubmitted(true);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isValid && checked) {
      axios.post(
        "https://sheet.best/api/sheets/de1b8e16-aeba-4db2-8b97-a39530a3076a",
        values
      );

      setsubmitted(true);
    }
  }, [errors, isValid, checked]);
  return (
    <>
      <MetaDecorator
        title={tags.contact.title}
        description={tags.contact.description}
        keywords={tags.contact.keywords}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeIn" }}
        className="FormSection"
      >
        <div>
          <h2>CONTACT US</h2>
          <hr className="headingUnderline"></hr>
        </div>
        {submitted ? (
          <div className="form">
            <p>Form submitted Succesfully!</p>
          </div>
        ) : (
          <>
            <form
              id="submit-form"
              className="form "
              ref={form}
              name="contactForm"
              onSubmit={sendEmail}
            >
              <input
                placeholder="Full Name"
                className={errors.FullName && "error"}
                required
                type="text"
                name="user_name"
              />
              <input
                placeholder="Email Address"
                required
                className={errors.FullName && "error"}
                type="text"
                name="user_email"
              />
              <input
                placeholder="Organization"
                required
                className={errors.FullName && "error"}
                type="text"
                name="user_organization"
              />
              <input
                placeholder="Contact Number"
                required
                className={errors.FullName && "error"}
                type="text"
                name="user_number"
              />
              <input
                placeholder="Country"
                required
                className={errors.FullName && "error"}
                type="text"
                name="user_country"
              />
              <textarea placeholder="How can we help you?" name="message" />

              <div
                key={0}
                initial="exit"
                animate="enter"
                exit="exit"
                className="fgroup"
              >
                <input type="checkbox" id="check" name="user_check" />
                <label htmlFor="check" className="mx-2">
                  {" "}
                  By submitting this form I give my consent for Pennonn to
                  process my personal data pursuant to{" "}
                  <span className="privacypolicy">Pennonn Privacy Policy</span>
                </label>
              </div>
              <input className="submitbtn" type="submit" value="SUBMIT" />
            </form>

            {/* <p>/////////////////////////////////////////////////////</p> */}

            {/* <form id="submit-form" className="form " onSubmit={handleSubmit}>
              <input
                type="text"
                className={errors.FullName && "error"}
                placeholder="Full Name"
                value={values.FullName}
                name="FullName"
                onChange={handleChange}
              />
              <input
                type="text"
                className={errors.Email && "error"}
                placeholder="Email Address"
                value={values.Email}
                name="Email"
                onChange={handleChange}
              />
              <input
                type="text"
                className={errors.Organisation && "error"}
                placeholder="Organization"
                value={values.Organisation}
                name="Organisation"
                onChange={handleChange}
              />
              <input
                type="text"
                className={errors.ContactNo && "error"}
                placeholder="Contact Number"
                value={values.ContactNo}
                name="ContactNo"
                onChange={handleChange}
              />

              <input
                type="text"
                className={errors.Country && "error"}
                placeholder="Country"
                value={values.Country}
                name="Country"
                onChange={handleChange}
              />
              <textarea
                type="text"
                className={errors.Description && "error"}
                placeholder="How can we help you?"
                value={values.Description}
                name="Description"
                onChange={handleChange}
              />
              <div
                key={0}
                initial="exit"
                animate="enter"
                exit="exit"
                className="fgroup"
              >
                <input
                  type="checkbox"
                  id="check"
                  name="check"
                  value={checked}
                  onChange={() => {
                    setchecked(!checked);
                  }}
                />
                <label htmlFor="check" className="mx-2">
                  {" "}
                  By submitting this form I give my consent for Pennonn to
                  process my personal data pursuant to{" "}
                  <span className="privacypolicy">Pennonn Privacy Policy</span>
                </label>
              </div>

              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 0.8, ease: "easeIn" }}
                className="submitbtn"
              >
                SUBMIT
              </motion.button>
            </form> */}
          </>
        )}
      </motion.div>
    </>
  );
}
