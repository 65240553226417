import React, { useEffect } from "react";
import "../Home.scss";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import environment from "../../../Environment/Environment";
export default function GetinTouch() {
  var hover = {
    backgroundPosition: "right",
  };
  const transition = {
    duration: 0.2,
    ease: "easeIn",
  };
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeIn",
        },
      });
      frameAnim.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeIn",
          delay: 0.4,
        },
      });
    } else {
      animation.start({
        y: 100,
        opacity: 0,
      });
      frameAnim.start({
        y: 100,
        opacity: 0,
      });
    }
  }, [inView]);
  const animation = useAnimation();
  const frameAnim = useAnimation();
  return (
    <div className="contact ">
      <div className="smoke">
        <div ref={ref} className="section ">
          <motion.div animate={animation} className="abouttexts">
            <p className="head">GET IN TOUCH</p>
            <h3>Start Your True Partnership With Us</h3>
            <p>
              {" "}
              Please fill out the form or e-mail us at{" "}
              <a href="mailto:info@pennonn.com">info@pennonn.com</a> and we will
              get back to you regarding your request.
            </p>
            <Link to="/contactus">
              <motion.button
                whileHover={hover}
                transition={transition}
                className="button"
              >
                Get in Touch
              </motion.button>
            </Link>
          </motion.div>
          <motion.div animate={frameAnim} className="map">
            <a href="https://goo.gl/maps/wKGeKCxMUpLbBo1u8" target="_blank">
              <img
                src={environment.mediaUrl + "/home/mapimg.png"}
                className="Gmap"
                alt=""
              />
            </a>
            <div className="officeLoc mt-3">
              <h3 className="Ohead">Visit our Office at</h3>
              <div className="group hOffice">
                <h5>Head Office</h5>
                <p>24710 Cobble Canyon Lane, Katy, Texas, 77494</p>
              </div>
              <div className="group office">
                <h5>Office</h5>
                <p>
                  Pennonn, 2nd floor, Siyad Tower, Azad Road, Kaloor, Ernakulam,
                  Kerala, India - 670326
                </p>
              </div>
              <h3 className="cHead">Connect with Us</h3>
              <div className="cIcon">
                <a href="https://www.facebook.com/pennonn" target="_blank">
                  <img
                    src={environment.mediaUrl + "/contactIcons/facebook.svg"}
                    alt=""
                  />
                </a>
                <a href="https://www.instagram.com/pennonn_official/" target="_blank">
                  <img
                    src={environment.mediaUrl + "/contactIcons/insta.svg"}
                    alt=""
                  />
                </a>
                <a
                  href="https://twitter.com/pennonn?s=08"
                  target="_blank"
                >
                  <img
                    src={environment.mediaUrl + "/contactIcons/twitter.svg"}
                    alt=""
                  />
                </a>
                <a href="https://www.whatsapp.com/" target="_blank">
                  <img
                    src={environment.mediaUrl + "/contactIcons/whatsapp.svg"}
                    alt=""
                  />
                </a>
              </div>
              <div className=" cNo">
                <img
                  src={environment.mediaUrl + "/contactIcons/phone.svg"}
                  alt=""
                />
                <p>
                  <a href="tel:+19736525023">(973) 652-5023</a>
                </p>
                <img
                  src={environment.mediaUrl + "/contactIcons/mail.svg"}
                  alt=""
                />
                <p>
                  <a href="mailto:info@pennonn.com">info@pennonn.com</a>
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
