import React from 'react'
import UnderConstruction from '../UnderConstruction/UnderConstruction'

export default function Career() {
    return (
        <div>
            <UnderConstruction/>
        </div>
    )
}
