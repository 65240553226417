import React from "react";
import { Helmet } from "react-helmet";
export default function MetaDecorator(props) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="title" content={props.title} />
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />

      <meta property="og:title" content="Pennonn | The Way Makers" />
      <meta
        property="og:image"
        content="https://media.pennonn.com/PennonnWebFiles/images/home/aboutImg.jpg"
      />
      <meta
        property="description"
        content="Pennonn is a Software Development Company offering digital services, application maintenance, digital transformation, cloud computing. With the vast and growing digital capabilities, See how Pennonn can transform digital work for your business."
      />
      <meta property="og:url" content="https://pennonn.com/" />
    </Helmet>
  );
}
