import React, { useState } from 'react'
import './Project.scss'
import { AnimatePresence, motion } from 'framer-motion'
import environment from '../../Environment/Environment'
import { Link } from 'react-router-dom'
import MetaDecorator from '../../utils/MetaTag/MetaDecorator'
import tags from '../../utils/MetaTag/MetaTags'
export default function Project(props) {
    const [wellcare, showwellcare] = useState(false)
    const [byrebarn, showbyrebarn] = useState(false)
    const [tripmocha, showtripmocha] = useState(false)
    const [shipex, showshipex] = useState(false)

    var imganim = {
        byrebarn: {
            opacity: !byrebarn ? 1 : 0,
            scale: !byrebarn ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut",
                delay: .5
            }
        },
        wellcare: {
            opacity: !wellcare ? 1 : 0,
            scale: !wellcare ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut",
                delay: .3

            }
        },
        tripmocha: {
            opacity: !tripmocha ? 1 : 0,
            scale: !tripmocha ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut",
                delay: .3

            }
        },
        shipex: {
            opacity: !shipex ? 1 : 0,
            scale: !shipex ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut",
                delay: .3

            }
        }
    }
    var divanim = {
        byrebarn: {
            opacity: byrebarn ? 1 : 0,
            scale: byrebarn ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut"
            }
        },
        wellcare: {
            opacity: wellcare ? 1 : 0,
            scale: wellcare ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut"
            }
        },
        tripmocha: {
            opacity: tripmocha ? 1 : 0,
            scale: tripmocha ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut"
            }
        },
        shipex: {
            opacity: shipex ? 1 : 0,
            scale: shipex ? 1 : 0,
            transition: {
                duration: .8,
                ease: "easeInOut"
            }
        }
    }
    return (
        <div className="project-mainsection" >
            <MetaDecorator title={tags.casestudy.title} description={tags.casestudy.description} keywords={tags.casestudy.keywords}/>
            <motion.div
                initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                transition={{ duration: .8, ease: "easeIn" }} className='project-headsection' >
                <div className="projecthead">
                    <h2>CASE STUDIES</h2>
                    <hr className='headingUnderline'></hr>
                </div>
                <div className="projectbody-section">
                    <div className="projects">
                        <motion.div className="wellcare" onMouseEnter={() => { showwellcare(true) }} onMouseLeave={() => { showwellcare(false) }}>
                            <AnimatePresence>

                                {!wellcare ?
                                    <motion.img initial={{ opacity: 0, scale: 0 }} animate={imganim.wellcare} src={environment.mediaUrl + "/projectLogos/wellcarelogo.png"} alt="" className='pgroup wellcareicon' />
                                    :
                                    <motion.div initial={{ opacity: 0, scale: 0 }} animate={divanim.wellcare} exit={{ scale: 0 }} className="wellcare_details project-details py-4">
                                        <h3>WellCare</h3>
                                        <p>Wellcare is a UK based company needs an online solution for
                                            marketing their specialized nursing care service across the Europe.
                                            Create a fast-loading web application.</p>
                                        <Link to="/projects/casestudies" onClick={() => props.setarray(0)}><button className='pBtn'  >Read More</button></Link>
                                    </motion.div>

                                }
                            </AnimatePresence>
                        </motion.div>
                        <div className="byrebarn" onMouseEnter={() => { showbyrebarn(true) }} onMouseLeave={() => { showbyrebarn(false) }}>
                            {!byrebarn ? <motion.img initial={{ opacity: 0, scale: 0 }} animate={imganim.byrebarn} src={environment.mediaUrl + "/projectLogos/byrebarnlogo.png"} alt="" className='pgroup byrebarnicon' /> :
                                <motion.div initial={{ opacity: 0, scale: 0 }} animate={divanim.byrebarn} className="byrebarn_details project-details py-4  ">
                                    <h3>ByreBarn</h3>
                                    <p>ByreBarn is the world's premier online animal auction website. Built by experienced farmers, ranchers and auctioneers to make a fair-priced, safe and secure online animal auction.</p>
                                    <Link to="/projects/casestudies" onClick={() => props.setarray(1)}><button className='pBtn'  >Read More</button></Link>

                                </motion.div>}
                        </div>
                        <div className="tripmocha" onMouseEnter={() => { showtripmocha(true) }} onMouseLeave={() => { showtripmocha(false) }}>

                            {!tripmocha ? <motion.img initial={{ opacity: 0, scale: 0 }} animate={imganim.tripmocha} src={environment.mediaUrl + "/projectLogos/tripmochalogo.png"} alt="" className='pgroup tripmochaicon' /> :
                                <motion.div initial={{ opacity: 0, scale: 0 }} animate={divanim.tripmocha} className="tripmocha_details project-details py-4    ">
                                    <h3>TripMocha</h3>
                                    <p>Incredible India and Australia tourism ministry want to jointly create  a portal to cater different offering like tourist attraction, hotel, etc to Incredible India program.</p>
                                    <Link to="/projects/casestudies" onClick={() => props.setarray(2)}><button className='pBtn'>Read More</button></Link>

                                </motion.div>}
                        </div>
                        <div className="shipex" onMouseEnter={() => { showshipex(true) }} onMouseLeave={() => { showshipex(false) }}>
                            {!shipex ? <motion.img initial={{ opacity: 0, scale: 0 }} animate={imganim.shipex} src={environment.mediaUrl + "/projectLogos/shipexLogo.png"} alt="" className='pgroup shipexicon' /> :
                                <motion.div initial={{ opacity: 0, scale: 0 }} animate={divanim.shipex} className="shipex_details project-details py-4  ">
                                    <h3>ShipEx</h3>
                                    <p>Enhance and migrate the current on-premises IT solution for a third party logistics company to cloud.</p>
                                    <Link to="/projects/casestudies" onClick={() => props.setarray(3)}><button className='pBtn'>Read More</button></Link>

                                </motion.div>}
                        </div>

                    </div>
                </div>
            </motion.div>
        </div >
    )
}

