import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import "../Home.scss";
import { Carousel } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
export default function Testimonial(props) {
  var hover = {
    backgroundPosition: "right",
  };
  const transition = {
    duration: 0.2,
    ease: "easeIn",
  };
  var init;
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeIn",
        },
      });
    } else {
      animation.start({
        y: 100,
        opacity: 0,
      });
    }
  }, [inView]);
  const animation = useAnimation();
  return (
    <div className="testimonial">
      <div className="skewed" style={{ paddingLeft: `${props.width}` }}>
        <motion.div
          ref={ref}
          animate={animation}
          initial={init}
          className="abouttexts "
        >
          <p className="head">TESTIMONIAL</p>
          <h3>Review from our clients</h3>
          <Carousel>
            <Carousel.Item>
              <div className="tCarousel">
                <p>
                  "Pennonn has helped ByreBarn from start-up ideation to fully
                  functional platform. They integrated our web and mobile
                  solution for sales, customer acquisition and multi-platform
                  development. They have expanded with us by provided
                  development, testing and integration teams at every stage of
                  our companies growth. They are a valued partner in our growth
                  and understand and share ByreBarn's mission."
                </p>
              </div>
              <h3>Dan Robinson</h3>
              <p>ByreBarn</p>
            </Carousel.Item>
            <Carousel.Item>
              <div className="tCarousel">
                <p>
                  "I am really impressed by the quality of services I received
                  from Pennonn. You were right on schedule, charged reasonable
                  prices, were professional and courteous in dealings, and
                  delivered items well before time. I have got a good web site
                  for my nursing home. I will definitely use your services
                  again."
                </p>
              </div>
              <h3>WellCare</h3>
              <p>CEO, WellCare</p>
            </Carousel.Item>
            <Carousel.Item>
              <div className="tCarousel">
                <p>
                  "We thank Pennonn for the wonderful job in helping us develop
                  our web application . Everyone was professional, excellent and
                  hard working. Thanks to them, we were able to achieve our goal
                  on time, and we look forward to continue working with them in
                  the future"
                </p>
              </div>
              <h3>TripMocha</h3>
              <p>CTO, TripMocha</p>
            </Carousel.Item>
          </Carousel>

          {/* <Link to="/projects">
            <motion.button
              whileHover={hover}
              transition={transition}
              className="button"
            >
              Read More
            </motion.button>
          </Link> */}
        </motion.div>
      </div>
    </div>
  );
}
