import React from 'react'
import './UnderConstruction.scss'
import environment from '../../Environment/Environment'
import { Link } from 'react-router-dom'
export default function UnderConstruction() {
    return (
        <div className="container-fluid p-5 mb-4">
            <div className="row d-flex align-center">
                <div className="col-md-12 col-lg-6 order-2 order-lg-1 ">
                    <img src={environment.mediaUrl+"/Settings.gif"} alt="maintanace" />
                    <h1>maintenance</h1>
                    <h2 className='constructionSubHead'>The page is currently under construction and will be back up in a few days.</h2>
                    <hr />
                    <p>If you need to you can always <Link to="/contactus">contact us.</Link></p>
                </div>
                <div className="col-md-12 col-lg-6 order-1 order-lg-2 ">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className='maintenanceIcon'
                        x="0px" y="0px" viewBox="0 0 1600 1200"  xmlSpace="preserve">

                        <g id="background">
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1084.8,284.4c0.2,3.1,0.3,6.2,0.5,9.2c0,0.6,0.4,1.4,1.1,1.4c84.2,11.6,169.1,1.8,253.6,2
                                               c23.9,0,47.7,0.9,71.5,3.2c0.6,0.1,1.3-0.5,1.4-1.1c0.8-4.1,1.7-8.2,2.5-12.3c0.4-1.9-2.5-2.7-2.9-0.8c-0.8,4.1-1.7,8.2-2.5,12.3
                                               c0.5-0.4,1-0.7,1.4-1.1c-83.7-8.2-167.7,2.1-251.6,0.3c-24.3-0.5-48.6-2.1-72.7-5.4c0.4,0.5,0.7,1,1.1,1.4
                                               c-0.2-3.1-0.3-6.2-0.5-9.2C1087.7,282.5,1084.7,282.5,1084.8,284.4L1084.8,284.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1087.3,285.9c84.6,0,169.3,0,253.9,0c24,0,48,0,72,0c1.9,0,1.9-3,0-3c-84.6,0-169.3,0-253.9,0
                                               c-24,0-48,0-72,0C1085.4,282.9,1085.4,285.9,1087.3,285.9L1087.3,285.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1105.5,283.5c2.5-35.9,4.6-71.9,7.7-107.7c0.4-4.7,0.9-9.3,1.7-13.9c0.3-1.7,0.4-5.2,1.4-6.6
                                               c1.3-1.8,4.1-1.8,6.1-2.1c4.6-0.8,12.3-3.6,15.5,1.1c0.9,1.4,1.1,4.3,1.4,6c0.6,3,1,6,1.3,9c1.6,17.6-0.7,35.5-2,53.1
                                               c-1.5,20.4-3,40.8-4.5,61.2c-0.1,1.9,2.9,1.9,3,0c2.2-29.2,4.6-58.3,6.4-87.5c0.8-13.4,1.2-27.1-2.1-40.3
                                               c-0.9-3.7-2.3-6.8-6.5-7.2c-6.4-0.5-13.8,1.7-20,3c-0.5,0.1-0.9,0.6-1,1c-5.3,20.7-5.1,43-6.6,64.2c-1.6,22.2-3.1,44.5-4.7,66.7
                                               C1102.4,285.4,1105.4,285.4,1105.5,283.5L1105.5,283.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1142.7,193.2c12.2,30.7,25.1,61.1,38.6,91.2c0.8,1.8,3.4,0.2,2.6-1.5c-13.4-29.9-26.2-60.1-38.3-90.5
                                               C1144.9,190.6,1142,191.4,1142.7,193.2L1142.7,193.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1145.3,177.1c2.5-7.1,10.2-11,17.4-9c1.9,0.5,2.7-2.4,0.8-2.9c-8.8-2.5-18.1,2.5-21.1,11.1
                                               C1141.8,178.2,1144.7,178.9,1145.3,177.1L1145.3,177.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1162.7,168.7c19.8,38.4,39.5,76.9,59.3,115.3c0.9,1.7,3.5,0.2,2.6-1.5c-19.8-38.4-39.5-76.9-59.3-115.3
                                               C1164.4,165.4,1161.8,166.9,1162.7,168.7L1162.7,168.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1214.8,142.9c3.2,46.5,6.4,93,9.5,139.5c0.1,1.9,3.1,1.9,3,0c-3.2-46.5-6.4-93-9.5-139.5
                                               C1217.7,141,1214.7,141,1214.8,142.9L1214.8,142.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1217.4,144.5c5.1-4.6,12-4.6,18.4-3.5c2.6,0.4,5.4,0.9,7.9,1.8c4.6,1.7,3.9,5.9,4.1,10
                                               c0.6,14.3,1.1,28.6,1.7,42.9c1.1,28.9,2.3,57.9,3.4,86.8c0.1,1.9,3.1,1.9,3,0c-1.4-34.6-2.7-69.2-4.1-103.7
                                               c-0.3-8.6-0.7-17.3-1-25.9c-0.2-4.4,0.4-9.8-4.2-12.2c-4.8-2.5-12.2-3.4-17.6-3.3c-5.2,0.1-9.8,1.5-13.6,5
                                               C1213.9,143.6,1216,145.8,1217.4,144.5L1217.4,144.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1165.1,167c9.5,0.6,18.8,3.4,27.9,6c8.4,2.4,16.6,5.4,23.7,10.6c1.6,1.1,3.1-1.5,1.5-2.6
                                               c-7.4-5.3-15.8-8.4-24.5-10.9c-9.4-2.7-18.9-5.4-28.7-6.1C1163.1,163.8,1163.1,166.8,1165.1,167L1165.1,167z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1251.3,161.7c4.4-1.1,9.3-2.2,13.9-1.3c6.8,1.4,8.2,7.4,8.5,13.5c0.5,12.3,0.7,24.5,1,36.8
                                               c0.6,24.2,0.9,48.4,1,72.6c0,1.9,3,1.9,3,0c-0.1-28.9-0.5-57.7-1.3-86.5c-0.2-7-0.4-13.9-0.6-20.9c-0.2-4.6-0.1-9.7-2.9-13.6
                                               c-5.2-7.3-15.9-5.5-23.3-3.6C1248.6,159.3,1249.4,162.2,1251.3,161.7L1251.3,161.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1273.2,145.9c0,9,0,17.9,0,26.9c0,1.9,3,1.9,3,0c0-9,0-17.9,0-26.9C1276.2,144,1273.2,144,1273.2,145.9
                                               L1273.2,145.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1277.6,147c0.8,0.3,1.7,0.6,2.5,0.9c0.1,0.7,0.2,1.4,0.3,2.2c0.2,1.6,0.2,3.3,0.4,5
                                               c0.2,2.6,0.4,5.3,0.6,7.9c0.4,5.6,0.7,11.3,1,16.9c0.7,11.6,1.2,23.2,1.6,34.8c0.9,22.9,1.2,45.8,1.2,68.7c0,1.9,3,1.9,3,0
                                               c0-28.5-0.6-57.1-1.9-85.6c-0.7-13.9-1.5-27.8-2.5-41.7c-0.2-3.4,0.4-9.6-2.7-12c-2.1-1.6-5-1-6.1,1.4
                                               C1274.3,147.3,1276.8,148.8,1277.6,147L1277.6,147z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1130.2,299.1c3.1,5.2,6.1,13,11.1,16.7c4.9,3.7,10.9,0.4,16.3,2.3c1.8,0.6,2.6-2.3,0.8-2.9
                                               c-4.8-1.7-10.4,1-14.7-1.7c-4.9-3-8-11.1-10.9-16C1131.8,295.9,1129.2,297.4,1130.2,299.1L1130.2,299.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1161.6,300.4c-0.4,5.2-2,9.9-4.6,14.3c-1,1.7,1.6,3.2,2.6,1.5c3-4.9,4.6-10.2,5-15.8
                                               C1164.8,298.5,1161.8,298.5,1161.6,300.4L1161.6,300.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1153.1,302.9c0.9,4.6,1.8,9.2,2.8,13.8c0.4,1.9,3.3,1.1,2.9-0.8c-0.9-4.6-1.8-9.2-2.8-13.8
                                               C1155.6,300.2,1152.7,301,1153.1,302.9L1153.1,302.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1156.7,302.8c0.2,0,0.4,0.1,0.6,0.2c0.3,0.6,0.3,1.2,0.2,1.8c-0.1,0.7-0.4,1.4-0.6,2.1
                                               c-0.4,1.4-0.5,2.7-0.1,4.1c0.6,1.9,3.2,1.1,2.9-0.8c-0.5-2.7-0.1-5.4,1-7.9c-1-0.1-2-0.3-2.9-0.4c-0.3,3.1-0.5,6.3-0.8,9.4
                                               c-0.2,1.9,2.8,1.9,3,0c0.3-3.1,0.5-6.3,0.8-9.4c0.2-1.8-2.4-1.8-2.9-0.4c-1.3,3.1-1.5,6.3-1,9.5c1-0.3,1.9-0.5,2.9-0.8
                                               c-0.9-3,2.6-6.7-0.4-9.4c-1.8-1.6-4.7-1-5.4,1.3C1153.2,303.9,1156.1,304.7,1156.7,302.8L1156.7,302.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1313.4,280.9c-0.9-3.7-1.3-7.3-1-11.1c-0.5,0.5-1,1-1.5,1.5c17.7-0.9,35.4-2,53.1-2.4
                                               c8.9-0.2,17.8-0.2,26.7,0c2,0,4,0.1,5.9,0.1c2,0.1,6.1-0.6,7.9,0.3c4,1.9,2.3,9.6,2.3,13.1c0,1.9,3,1.9,3,0c0-4.7,2.4-14.7-4-16
                                               c-4.4-0.9-9.6-0.4-14.1-0.5c-5.6-0.1-11.2-0.2-16.8-0.1c-21.3,0.1-42.6,1.4-64,2.5c-0.8,0-1.5,0.7-1.5,1.5
                                               c-0.2,4.1,0.1,8,1.2,11.9C1310.9,283.6,1313.8,282.8,1313.4,280.9L1313.4,280.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1324.5,250.8c1.2,5.5,2.1,11,2.5,16.6c0.1,1.9,3.1,1.9,3,0c-0.4-5.9-1.3-11.7-2.6-17.4
                                               C1327,248.2,1324.1,249,1324.5,250.8L1324.5,250.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1326.5,252.7c12.1-1,24.2-2.1,36.3-1.6c11.4,0.5,22.9,4.2,34.3,3c-0.5-0.5-1-1-1.5-1.5
                                               c-0.4,5.3,0.2,10.3,1.9,15.4c0.6,1.8,3.5,1,2.9-0.8c-1.6-4.8-2.2-9.5-1.8-14.6c0.1-0.8-0.7-1.6-1.5-1.5
                                               c-11.4,1.1-22.9-2.6-34.3-3c-12.1-0.5-24.3,0.6-36.3,1.6C1324.6,249.8,1324.6,252.8,1326.5,252.7L1326.5,252.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1224.7,563c0,4.2,0,8.3,0,12.5c0,1.9,3,1.9,3,0c0-4.2,0-8.3,0-12.5C1227.7,561.1,1224.7,561.1,1224.7,563
                                               L1224.7,563z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1226.2,562c77.8,0,155.6,0,233.4,0c1.9,0,1.9-3,0-3c-77.8,0-155.6,0-233.4,0
                                               C1224.2,559,1224.2,562,1226.2,562L1226.2,562z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1228.6,575.8c76.6,0,153.1,0,229.7,0c1.9,0,1.9-3,0-3c-76.6,0-153.1,0-229.7,0
                                               C1226.7,572.8,1226.7,575.8,1228.6,575.8L1228.6,575.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1458.1,563c0,2.9,0,5.8,0,8.7c0,1.9,3,1.9,3,0c0-2.9,0-5.8,0-8.7C1461.1,561.1,1458.1,561.1,1458.1,563
                                               L1458.1,563z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1247.8,529.3c2.6,10.3,5,20.7,7,31.1c0.4,1.9,3.3,1.1,2.9-0.8c-2-10.4-4.3-20.8-7-31.1
                                               C1250.2,526.6,1247.3,527.4,1247.8,529.3L1247.8,529.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1299.8,528.4c-2.4,10.3-4.4,20.6-5.9,31c-0.3,1.9,2.6,2.7,2.9,0.8c1.5-10.4,3.5-20.8,5.9-31
                                               C1303.1,527.3,1300.2,526.5,1299.8,528.4L1299.8,528.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1239.6,523.7c0.2,5.2,8,4.8,11.8,5c8.6,0.4,17.3-0.9,25.9-1.4c4.3-0.3,8.6-0.4,12.9,0
                                               c3.5,0.3,7.3,1.5,10.7,0.8c3.8-0.8,6.2-4.4,4-7.9c-1-1.6-3.6-0.1-2.6,1.5c2.8,4.7-6.7,3.2-7.6,3.1c-2.1-0.3-4.3-0.5-6.4-0.6
                                               c-4.9-0.3-9.9,0-14.8,0.4c-3.2,0.2-30.8,3.5-30.9-0.9C1242.6,521.7,1239.6,521.7,1239.6,523.7L1239.6,523.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1243.1,523.3c-1.8-5.6,13.7-5,16.3-5.1c4.6-0.2,9.3-0.4,13.9-0.6c2.5-0.1,28.5-0.8,27.8,1.1
                                               c-0.6,1.8,2.3,2.6,2.9,0.8c3-8.9-29.5-5-33.7-4.8c-7.8,0.3-16.5-0.4-24.1,1.1c-4,0.8-7.4,3.8-6,8.2
                                               C1240.8,525.9,1243.7,525.1,1243.1,523.3L1243.1,523.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1256.4,515c-4.6-2.1-8.7-5.1-11.9-8.9c-1.3-1.6-10.5-14.9-5.1-15.7c0.9-0.1,1.4-1.5,0.9-2.2
                                               c-2.9-4.2-4.1-9.6-5.6-14.4c-1.7-5.2-3.3-10.4-5-15.5c-0.3-1.1-0.8-2.1-1-3.2c-0.5-2.1-0.8-2.1,1.7-2.3c2.7-0.2,4.1,1.9,5.9,3.7
                                               c3.4,3.4,6.4,7.1,9.1,11c10.1,14.6,15.7,31.8,19.9,48.9c0.5,1.9,3.4,1.1,2.9-0.8c-5.4-21.8-13.3-44.2-29.3-60.7
                                               c-2.2-2.3-6.4-7.6-10.1-7c-2.8,0.5-3.6,3.5-3.4,6c0.4,5.6,3.4,11.7,5.2,17.1c2,6.2,3.3,13.4,7.1,18.8c0.3-0.7,0.6-1.5,0.9-2.2
                                               c-6.6,1-2.8,10.2-1,13.8c3.8,7.3,9.9,12.9,17.3,16.4C1256.6,518.4,1258.1,515.8,1256.4,515L1256.4,515z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1259.8,487.6c-0.3-12.7-0.6-25.9,3.7-38c2.1-5.9,5.3-11.2,9-16.2c0.9-1.3,1.9-3.4,3.3-4.2
                                               c1-1.6,2.5-1.8,4.4-0.5c2.4-0.6,3.6,0,3.6,1.8c1.2,2.7,0.8,7.3,0.8,10.1c0,6.7-1.6,12.9-3.2,19.3c-0.6,2.2-1.1,4.5-0.9,6.7
                                               c0.2,2.6,1.6,4.6,2.3,7c2,6.8-1.5,13.8-3.9,20c-2.4,6.5-4.9,13-7.3,19.6c-0.7,1.8,2.2,2.6,2.9,0.8c3.2-8.6,6.6-17.1,9.6-25.8
                                               c2.1-6.1,3.1-11.4,0.7-17.4c-3.2-8.1,1.9-17.2,2.6-25.5c0.4-5.4,1.3-14.6-2.2-19.4c-7.6-10.5-19.7,11.9-22.1,16.9
                                               c-6.8,14-6.7,29.7-6.3,44.9C1256.9,489.6,1259.9,489.6,1259.8,487.6L1259.8,487.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1234.6,463c9.9,13.9,15.8,30.6,22.7,46.1c0.8,1.8,3.4,0.2,2.6-1.5c-6.8-15.6-12.8-32.2-22.7-46.1
                                               C1236.1,459.9,1233.5,461.4,1234.6,463L1234.6,463z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1262.2,455.9c0.4,5.3,2.2,10,5.5,14.1c1.2,1.5,3.3-0.6,2.1-2.1c-2.7-3.5-4.3-7.6-4.6-12
                                               C1265.1,454,1262.1,454,1262.2,455.9L1262.2,455.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1275.8,455.2c2.4-3.6,4.9-7.2,7.3-10.8c1.1-1.6-1.5-3.1-2.6-1.5c-2.4,3.6-4.9,7.2-7.3,10.8
                                               C1272.2,455.3,1274.8,456.8,1275.8,455.2L1275.8,455.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1305.6,453.2c-2.5,4-6.1,7.1-9.4,10.3c-3.6,3.4-7.2,6.9-10.8,10.3c-1.4,1.3,0.7,3.5,2.1,2.1
                                               c6.9-6.6,15.6-13,20.7-21.2C1309.2,453.1,1306.6,451.6,1305.6,453.2L1305.6,453.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1304.3,457.2c0.2,5.3,9,15.1,2.2,18.3c-0.9,0.5-0.9,1.7-0.3,2.4c2.3,2.8,0.8,7,0.5,10.3
                                               c-0.3,3.7,0,7.4-1,11c-1.8,6.9-8.2,11-13.9,14.6c-1.6,1-0.1,3.6,1.5,2.6c6.9-4.4,14-9.4,15.6-18c0.8-4.6,0.8-9.4,1.2-14
                                               c0.4-3.3,0.3-5.9-1.9-8.5c-0.1,0.8-0.2,1.6-0.3,2.4c9.5-4.5-0.5-14.7-0.7-20.9C1307.2,455.3,1304.2,455.3,1304.3,457.2
                                               L1304.3,457.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1299.5,471.9c-6.9,11.1-12.8,22.7-17.7,34.9c-0.7,1.8,2.2,2.6,2.9,0.8c4.8-11.9,10.6-23.3,17.4-34.2
                                               C1303.1,471.7,1300.5,470.2,1299.5,471.9L1299.5,471.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1253.1,549.8c4,5.4,9.5,9.1,16.1,10.5c1.9,0.4,2.7-2.5,0.8-2.9c-5.8-1.2-10.8-4.3-14.3-9.1
                                               C1254.6,546.8,1252,548.3,1253.1,549.8L1253.1,549.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1251.4,537.5c10.3,7.9,21.2,19.2,34.4,21.9c1.9,0.4,2.7-2.5,0.8-2.9c-12.8-2.6-23.6-13.9-33.7-21.6
                                               C1251.4,533.7,1249.9,536.3,1251.4,537.5L1251.4,537.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1259.3,529.9c6.2,5.1,12.2,10.3,18.5,15.2c2.7,2.1,5.5,4.1,8.8,5.4c3.1,1.3,6.4,1.9,9.2,3.9
                                               c1.6,1.1,3.1-1.5,1.5-2.6c-2.2-1.5-4.4-2.2-6.8-3.1c-4.1-1.4-7.4-3.3-10.8-6c-6.2-4.8-12.2-9.9-18.2-14.9
                                               C1259.9,526.6,1257.8,528.7,1259.3,529.9L1259.3,529.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1275.7,528.5c5.8,6,12.8,10.4,20.8,13c1.8,0.6,2.6-2.3,0.8-2.9c-7.5-2.5-14-6.6-19.5-12.3
                                               C1276.5,525,1274.3,527.1,1275.7,528.5L1275.7,528.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1349.3,557.6c-0.6-1.6-1.5-3.7-1.4-5.4c0.4-4.4,3.4-4.8,6.3-5.3c4.2-0.8,8.6-0.9,12.9-0.9
                                               c7.3-0.1,14.7,0.5,22,0.8c7.3,0.2,14.5-0.2,21.8-0.8c3-0.3,8.3-1.5,11.2-0.5c3,1.1,3.8,2.6,3.9,5.4c0.1,2.2,0.1,4.4,0.2,6.7
                                               c0.1,1.9,3.1,1.9,3,0c-0.1-3.4,0.9-9.4-0.8-12.6c-1.6-2.8-5.3-2.5-8.1-2.5c-11.4,0-22.5,1.7-34,1.2c-10.6-0.4-21.3-1.3-31.8,0.3
                                               c-8.6,1.3-10.8,6.6-7.9,14.6C1347,560.2,1349.9,559.4,1349.3,557.6L1349.3,557.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1370.5,541.5c0.8-1.8,1.5-3.6,2.3-5.4c0.9-0.1,1.8-0.2,2.7-0.4c1.2-0.1,2.3,0,3.5-0.1
                                               c2.6,0,5.1-0.1,7.7-0.1c5.5-0.1,10.9-0.1,16.4-0.2c5.1-0.1,10.3-0.1,15.4-0.2c1.3,0,2.6,0,3.9-0.1c1.6,0,2.1-0.4,2.6,0.8
                                               c0.6,1.5-0.5,5.7-0.7,7.3c-0.2,1.9,2.8,1.9,3,0c0.2-2.4,1.7-7.4,0.5-9.6c-1-1.8-2.5-1.5-4.5-1.5c-9.6,0-19.3,0.3-28.9,0.4
                                               c-4.5,0.1-9,0.1-13.5,0.2c-3.4,0-7.6-0.4-10.7,1.2c-3.6,1.9-5.3,6.8-1.8,9.7C1369.9,544.8,1372,542.7,1370.5,541.5L1370.5,541.5z
                                               " />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1391.1,529.3c-2.6-3.5,8.3-3.1,9-3.2c2.4-0.2,10.6-1.7,9,3.7c-0.6,1.9,2.3,2.6,2.9,0.8
                                               c0.7-2.4-0.1-4.6-2-6.2c-3.3-2.7-7.4-1.4-11.3-1.2c-3.1,0.1-6.8-0.9-9.3,1.5c-1.8,1.6-2.3,4.1-0.9,6.1
                                               C1389.7,532.3,1392.3,530.8,1391.1,529.3L1391.1,529.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1238.1,578c3.8,5.2,8.3,9.5,13.6,13.1c1.6,1.1,3.1-1.5,1.5-2.6c-4.8-3.3-9.1-7.3-12.5-12.1
                                               C1239.5,574.9,1236.9,576.4,1238.1,578L1238.1,578z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1251.3,590.1c3.3,0,6.6,0,9.9,0c1.9,0,5,0.5,6.7-0.4c1.3-0.7,1.7-2.3,2.3-3.5c1.6-3.2,3.5-6.2,5.7-9.1
                                               c1.2-1.5-0.9-3.6-2.1-2.1c-1.1,1.3-2.1,2.7-3.1,4.1c-1.2,1.9-2,4.6-3.4,6.3c-2.8,3.3-11.9,1.8-16,1.7
                                               C1249.4,587.1,1249.4,590.1,1251.3,590.1L1251.3,590.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1262.4,577.8c0.8,3,1.6,6.1,2.5,9.1c0.5,1.9,3.4,1.1,2.9-0.8c-0.8-3-1.6-6.1-2.5-9.1
                                               C1264.8,575.1,1261.9,575.9,1262.4,577.8L1262.4,577.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1384.3,577.3c1.6,3.3,3.3,6.5,5.4,9.5c1.1,1.6,2.5,4.1,4.4,5c2,1,4.8,0.2,6.8,0c3.6-0.4,7.2-0.7,10.8-1.1
                                               c1.9-0.2,1.9-3.2,0-3c-2.4,0.2-4.8,0.5-7.2,0.7c-2.1,0.2-5.2,1.1-7.2,0.7c-5.2-1-8.4-9.2-10.4-13.4
                                               C1386,574,1383.4,575.5,1384.3,577.3L1384.3,577.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1411.8,574.8c-1.2,4.3-1.8,8.7-1.7,13.1c0.1,1.9,3.1,1.9,3,0c-0.1-4.2,0.4-8.3,1.5-12.3
                                               C1415.2,573.8,1412.3,573,1411.8,574.8L1411.8,574.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1413.1,589.4c4-3.8,7.8-7.8,11.5-11.9c1.3-1.4-0.8-3.6-2.1-2.1c-3.7,4.1-7.5,8.1-11.5,11.9
                                               C1409.6,588.6,1411.7,590.7,1413.1,589.4L1413.1,589.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M770.9,284.4c0,28.2,0,56.3,0,84.5c0,1.9,3,1.9,3,0c0-28.2,0-56.3,0-84.5
                                               C773.9,282.5,770.9,282.5,770.9,284.4L770.9,284.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M773.3,371.2c35.7,0,71.5,0,107.2,0c1.9,0,1.9-3,0-3c-35.7,0-71.5,0-107.2,0
                                               C771.3,368.2,771.3,371.2,773.3,371.2L773.3,371.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M773.3,285.9c34.7,0,69.3,0,104,0c1.9,0,1.9-3,0-3c-34.7,0-69.3,0-104,0
                                               C771.3,282.9,771.3,285.9,773.3,285.9L773.3,285.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M877.3,284.4c0,28.2,0,56.3,0,84.5c0,1.9,3,1.9,3,0c0-28.2,0-56.3,0-84.5
                                               C880.3,282.5,877.3,282.5,877.3,284.4L877.3,284.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M826.7,281.9c-0.1-1.3,0.1-2.5,0.7-3.7c0.3-0.7,1.5-2.5,2.2-2c0.8,0.5,1.1,2.8,1.2,3.6
                                               c0.3,1.5,0.3,3,0.1,4.5c-0.2,1.9,2.8,1.9,3,0c0.2-2.6,0-5.2-0.9-7.7c-0.6-1.6-1.4-3.5-3.3-3.7c-4.1-0.5-6.2,5.8-6,8.9
                                               C823.8,283.9,826.8,283.9,826.7,281.9L826.7,281.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M776.6,294.1c0,22.5,0,44.9,0,67.4c0,1.9,3,1.9,3,0c0-22.5,0-44.9,0-67.4
                                               C779.6,292.2,776.6,292.2,776.6,294.1L776.6,294.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M778.1,363c31.4,0,62.8,0,94.2,0c1.9,0,1.9-3,0-3c-31.4,0-62.8,0-94.2,0C776.2,360,776.2,363,778.1,363
                                               L778.1,363z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M874.7,360.7c0-22.5,0-44.9,0-67.4c0-1.9-3-1.9-3,0c0,22.5,0,44.9,0,67.4
                                               C871.7,362.7,874.7,362.7,874.7,360.7L874.7,360.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M778.9,296.4c30.3,0,60.6,0,91,0c1.9,0,1.9-3,0-3c-30.3,0-60.6,0-91,0C777,293.4,777,296.4,778.9,296.4
                                               L778.9,296.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M779.4,347.1c11.7-3.5,23.6-6.6,35.2-1.2c8.3,3.9,15.5,9.8,24.8,11.5c1.9,0.3,2.7-2.5,0.8-2.9
                                               c-10.6-2-18.6-9.7-28.7-13.1c-10.9-3.6-22.3-0.4-32.9,2.8C776.8,344.8,777.6,347.7,779.4,347.1L779.4,347.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M818.4,345.4c14.3-5,29.2-6.8,44.2-5.3c1.9,0.2,1.9-2.8,0-3c-15.3-1.5-30.5,0.3-45,5.4
                                               C815.8,343.2,816.6,346.1,818.4,345.4L818.4,345.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M821.5,322.2c-1.1-1.9-0.8-4.7,1.1-6c1.7-1.2,4.4-0.7,5.6,0.9c1.2,1.7,0.5,4.4-1.4,5.4
                                               c-2.2,1.1-4.7-1-4.7-3.2c0-1.9-3-1.9-3,0c-0.1,4,4,7.4,8,6.3c3.7-1,5.7-5.7,4.1-9.1c-1.6-3.6-6.4-4.9-9.8-3.1
                                               c-3.7,2-4.6,6.9-2.6,10.4C819.9,325.4,822.4,323.9,821.5,322.2L821.5,322.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1308.6,298.9c3.6,4.8,4.3,11.3,8.7,15.5c4.1,4,9,3.4,14,1.6c1.8-0.6,1-3.5-0.8-2.9
                                               c-2.7,0.9-5.8,1.9-8.6,0.8c-2.4-1-4-3.2-5.2-5.5c-1.9-3.7-3-7.7-5.5-11.1C1310,295.8,1307.4,297.3,1308.6,298.9L1308.6,298.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1330.9,314.8c4.8-4.1,9-8.8,12.6-14c1.1-1.6-1.5-3.1-2.6-1.5c-3.4,5-7.5,9.5-12.1,13.4
                                               C1327.3,313.9,1329.4,316.1,1330.9,314.8L1330.9,314.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1323.6,297.8c1.4,3.3,2,6.7,1.9,10.2c-0.1,1.9,2.9,1.9,3,0c0.1-4.1-0.7-8-2.3-11.7
                                               C1325.5,294.5,1322.9,296,1323.6,297.8L1323.6,297.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1243.5,689c0,13.3,0,26.7,0,40c0,1.9,3,1.9,3,0c0-13.3,0-26.7,0-40C1246.5,687.1,1243.5,687.1,1243.5,689
                                               L1243.5,689z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1207,734.5c35.3,0,70.7,0,106,0c1.9,0,1.9-3,0-3c-35.3,0-70.7,0-106,0
                                               C1205.1,731.5,1205.1,734.5,1207,734.5L1207,734.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1264.5,737c0,8.3,0,16.7,0,25c0,1.9,3,1.9,3,0c0-8.3,0-16.7,0-25C1267.5,735.1,1264.5,735.1,1264.5,737
                                               L1264.5,737z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1206,766.5c31.3,0,62.7,0,94,0c1.9,0,1.9-3,0-3c-31.3,0-62.7,0-94,0C1204.1,763.5,1204.1,766.5,1206,766.5
                                               L1206,766.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1229.5,769c0,6.3,0,12.7,0,19c0,1.9,3,1.9,3,0c0-6.3,0-12.7,0-19C1232.5,767.1,1229.5,767.1,1229.5,769
                                               L1229.5,769z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1239.5,284.8c0-4.2-0.8-9.5,0-13.6c1.1-5.8,9.2-4.7,13.9-5c1.9-0.1,1.9-3.1,0-3c-3.8,0.2-7.8,0.2-11.6,0.8
                                               c-1.7,0.3-3.2,0.8-4.2,2.3c-1.2,1.8-1,4.3-1,6.4c0,4,0,8.1,0,12.1C1236.5,286.8,1239.5,286.8,1239.5,284.8L1239.5,284.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1221.9,173.1c9.1-2.5,18.2-1.9,26.9,1.6c1.8,0.7,2.6-2.2,0.8-2.9c-9.2-3.7-18.9-4.2-28.5-1.6
                                               C1219.2,170.8,1220,173.7,1221.9,173.1L1221.9,173.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M1218.2,164.1c8-5.5,17.6-6.5,26.6-2.9c1.8,0.7,2.6-2.2,0.8-2.9c-9.6-3.9-20.4-2.7-28.9,3.2
                                               C1215.1,162.6,1216.6,165.2,1218.2,164.1L1218.2,164.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0"
                                        d="M1185.7,216.5c-3.2,2.2-9.3,5.1-10.2,9.3c-0.4,2.2,0.6,4.2,1.4,6.2c1.4,3.5,2.8,7,4.3,10.5
                                               c5.8,13.7,12.3,27.1,19.5,40.1c0.9,1.7,3.5,0.2,2.6-1.5c-5.5-10-10.6-20.2-15.3-30.6c-2.5-5.5-4.9-11.1-7.1-16.7
                                               c-1.3-3.3-3.1-6.2-0.7-9.6c1.4-2,5-3.7,7.1-5.1C1188.8,218,1187.3,215.4,1185.7,216.5L1185.7,216.5z" />
                                </g>
                            </g>
                        </g>
                        <g id="hand_r">
                            <path className="st1" d="M765.2,706.6c-8.2,6.1-18.1,15.9-21.7,29.9c-2.6,10.4-0.1,16.5-1,29.9c-0.6,9.3-3,22.4-10.7,38
                               c-17.5-3.6-46.9-7.1-81.6,0.9c-20.7,4.8-37.3,12.3-49.5,19.1c1.9,1.2,4.5,2.6,7.8,3.5c18.7,5.1,38.4-10.6,39-9.5
                               c0.6,1-22,11.6-20.8,20.8c0.8,6.2,11.9,9.5,23.4,13c9.7,2.9,18.9,5.7,31.2,4.3c16.9-1.9,28.6-10.4,33.8-14.8
                               c5,2.5,12.4,5.8,21.7,8.7c17.1,5.2,40.7,12.4,56.4,2.6c10.2-6.4,13.3-21.4,19.1-51.2c7-36.3,10.3-54.5,0.9-69.4
                               C800.9,712.5,775.6,707.9,765.2,706.6z" />
                            <g>
                                <g>
                                    <path className="st2" d="M728.6,805.5c12.1,4.5,22.1,12.7,29,23.6c1,1.6,3.6,0.1,2.6-1.5c-7.3-11.6-18-20.2-30.8-25
                                               C727.5,801.9,726.8,804.8,728.6,805.5L728.6,805.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M664.2,825.2c-11.9,2-21.5,10-25.8,21.2c-0.7,1.8,2.2,2.6,2.9,0.8c3.9-10.2,12.9-17.3,23.7-19.1
                                               C666.9,827.8,666.1,824.9,664.2,825.2L664.2,825.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M678.2,835.2c-4.3,2.6-8.3,5.6-12.1,8.8c-2.5,2.2-5.3,4.8-4.7,8.4c1.5,9.5,12.9,7.4,19.1,5
                                               c1.8-0.7,1-3.6-0.8-2.9c-2.7,1-5.7,2.3-8.6,2.2c-4.7-0.2-8.9-4.7-4.9-8.8c3.8-4,8.8-7.2,13.5-10.2
                                               C681.3,836.7,679.8,834.1,678.2,835.2L678.2,835.2z" />
                                </g>
                            </g>
                            <path className="st3" d="M858.5,583.5c-5.1,1.7-11.6,4.3-18.9,7.9c-2.1,1-7.4,3.7-13.1,7.4c-8.8,5.6-15.2,11.5-22.5,18.9
                               c-9.3,9.5-18.7,19-29.9,34.2c-9.4,12.8-24,32.9-34.5,62.4c-4.2,11.7-6.6,21.8-8,29c5.5-3.2,10.5-4.8,14.4-5.7
                               c22.5-5.1,44.1,6.3,64.6,17c7.7,4,13.8,7.9,18,10.6c2.2-10.8,4.4-21.7,6.6-32.5c1.9-9.1,3.7-18.3,5.6-27.4c2.9-2.5,6.8-6.2,11.1-11
                               c8.1-9.2,24.2-27.5,26.4-52.9C881,612.1,863.6,589.7,858.5,583.5z" />
                            <g>
                                <g>
                                    <path className="st2" d="M852.6,584.7c-10.7,1.6-19.9,7.3-28.2,13.9c-9.6,7.5-18.6,15.8-27,24.6c-16.4,17-30.7,36-42.5,56.5
                                               c-1,1.7,1.6,3.2,2.6,1.5c11.4-19.8,25-38.2,40.8-54.7c7.7-8,15.8-15.6,24.3-22.7c9.1-7.5,18.8-14.5,30.8-16.3
                                               C855.3,587.3,854.5,584.4,852.6,584.7L852.6,584.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M848.4,693.9c-7.4,7.5-15,14.8-22.7,21.9c-1.4,1.3,0.7,3.4,2.1,2.1c7.7-7.1,15.3-14.4,22.7-21.9
                                               C851.8,694.6,849.7,692.5,848.4,693.9L848.4,693.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M838,707.6c-3.5,19.2-6.9,38.5-10.4,57.7c0.7-0.3,1.5-0.6,2.2-0.9c-15.9-11.1-33.6-19.4-52.4-24.6
                                               c-14.7-4-33.4-7.9-46,3.2c-1.4,1.3,0.7,3.4,2.1,2.1c12.8-11.4,33.5-5.4,48-1c16.7,5.1,32.4,12.9,46.7,22.8c0.8,0.5,2,0,2.2-0.9
                                               c3.5-19.2,6.9-38.5,10.4-57.7C841.2,706.5,838.3,705.7,838,707.6L838,707.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M755.6,678.3c-10.4,20.3-23.4,41.2-24.3,64.7c-0.1,1.9,2.9,1.9,3,0c0.9-22.9,13.7-43.3,23.9-63.2
                                               C759,678.1,756.4,676.6,755.6,678.3L755.6,678.3z" />
                                </g>
                            </g>
                            <path className="st4" d="M844.5,640.5c-17.9,35.6-27.6,65.6-33.3,86.6c-8.3,30.8-11.2,55.1-32.7,75.3c-3.5,3.3-13.8,12.8-30,18.7
                               c-34,12.3-59.2-4.3-80.6,13.3c-2.4,2-9.5,7-8.7,12.7c0.8,5.4,8.2,8.2,9.3,8.7c8.8,3.4,17.9-0.1,29.3-4.7c12.9-5.2,11.5-7,17.3-8
                               c12.2-2.1,17.8,5.6,34,10.7c6.9,2.1,27.3,8.5,42,0c10.8-6.3,14.1-18.1,19.3-38c3.6-13.5,7.3-33.2,6.7-58c4,3.1,8,6.2,12,9.3
                               C834.3,724.9,839.4,682.7,844.5,640.5z" />
                            <path className="st5" d="M838.5,683.8c-13.7,25.9-20.4,47.8-24,62.6c-7.2,29.7-4.9,43.7-14.7,64.6c-10.5,22.7-27.5,37.1-39.3,45.3
                               c6.6,1.5,22.5,4.3,34-4.7c8.1-6.3,10.2-15.3,13.3-30c3.9-18.1,8-38.7,12-61.3c3.1,2,6.2,4,9.3,6
                               C832.3,738.9,835.4,711.3,838.5,683.8z" />
                        </g>
                        <g id="torso">
                            <path className="st3"
                                d="M856.4,581.5c-3.6,23.5-7.1,47.2-10.5,71c-13.2,92.3-24.6,183-34.2,272.2c0.2,3,1,7.8,4,12.6
                               c3,4.9,7,7.4,8.9,8.7c18.2,13,27.3,19.6,36.5,24c44.1,21.3,92.9,18.8,92.9,18.8c10.3-0.5,30.8-1.8,52.8-10.6
                               c1.2-0.5,9.6-4.4,26.4-12.3c15.2-7.1,17.9-8.5,21.6-11.9c8.8-8.1,12.2-18.1,14.5-25.2c4.9-15.1,4.4-27.9,4.2-31
                               c0,0-1-24.9-1.7-170.3c-0.1-29.6,2.8-73.1,18.4-126.8c-16.2-7.9-31.4-15.2-49.7-24c-12.6-6.1-24.3-11.7-41.1-13.7
                               c-4.8-0.6-9.1-0.8-15.5-0.7c-26,0.2-47.4,4.2-56.6,5.8C909.4,571,885.2,575.4,856.4,581.5z" />
                            <g>
                                <g>
                                    <path className="st2" d="M855.6,585c31-6.6,62.3-11.9,93.7-15.8c1.4-0.2,2.2-2,0.8-2.8c-0.4-0.2-0.8-0.5-1.3-0.7
                                               c-1.7-1-3.2,1.6-1.5,2.6c0.4,0.2,0.8,0.5,1.3,0.7c0.3-0.9,0.5-1.9,0.8-2.8c-31.7,3.9-63.3,9.2-94.5,15.9
                                               C852.9,582.5,853.7,585.4,855.6,585L855.6,585z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M846.3,637.4c-11,78.4-21,156.9-30,235.6c-1.1,9.9-2.2,19.7-3.3,29.6c-0.9,8.6-3.4,18.3-2.3,26.9
                                               c1,7.6,7,12.4,12.5,17c6,5,12.3,9.7,18.9,14c27.1,17.5,58.8,28,91,29.9c38.3,2.2,76.8-7.6,109.5-27.6c1.6-1,0.1-3.6-1.5-2.6
                                               c-57.2,34.9-130,36.3-188.3,3.2c-6.7-3.8-13.2-8-19.4-12.6c-6.3-4.7-16.5-11.1-19.1-18.8c-1.2-3.7-0.6-7.8-0.2-11.7
                                               c0.5-4.9,1.1-9.9,1.6-14.8c1-9.2,2-18.4,3.1-27.6c4.3-37.5,8.7-74.9,13.5-112.3c5.4-42.5,11-85,17-127.4
                                               C849.5,636.3,846.6,635.5,846.3,637.4L846.3,637.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M993.6,562.1c17.6,3.5,34.1,11.6,50.5,18.5c16.1,6.8,34,12.3,48.2,22.7c1.6,1.1,3.1-1.5,1.5-2.6
                                               c-13.9-10.2-31.4-15.6-47.1-22.2c-17-7.1-34.1-15.6-52.3-19.3C992.5,558.8,991.7,561.7,993.6,562.1L993.6,562.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M1072,731.7c-1.6,42.1-3.2,84.2-1.7,126.2c0.7,20.4,3.9,41.6,0.1,61.9c-3.2,16.7-12.6,33.4-29.3,39.6
                                               c-1.8,0.7-1,3.6,0.8,2.9c26.6-9.9,33.5-40.2,33.1-65.6c-0.2-17.2-1.7-34.4-2.1-51.7c-0.4-17.6-0.2-35.1,0.1-52.7
                                               c0.4-20.2,1.2-40.4,1.9-60.6C1075.1,729.7,1072.1,729.7,1072,731.7L1072,731.7z" />
                                </g>
                            </g>
                            <path className="st5"
                                d="M1057.6,708c-5.3,18.2-13.6,38.1-26.4,57.9c-57.3,88.1-162.2,105.7-189,109.4c36,3.8,72.4,7.9,109.2,12.3
                               c40.6,4.8,80.7,9.9,120.2,15.1c-2.5-44.7-2.5-81.5-1.9-107.8c1.1-54.4,5-98.4-2.1-99.8C1065.9,694.7,1062.9,696.6,1057.6,708z" />
                            <path className="st6" d="M1069.9,740.7c-3.6,10.6-9.1,25.6-17.2,42.9c-11.4,24.5-22.3,48.1-36.4,62.6c-39.4,40.5-116.4,23-116.6,25.8
                               c-0.1,1.6,28.1,6.1,170.5,19.2C1070.2,841,1070.1,790.9,1069.9,740.7z" />
                        </g>
                        <g id="table">
                            <path className="st1" d="M1249.3,1153c-367-49-734.1-98-1101.1-146.9c108.3-62.8,216.7-125.6,325-188.4
                               c331.1,32.6,662.3,65.3,993.4,97.9C1394.1,994.7,1321.7,1073.9,1249.3,1153z" />
                            <path className="st7" d="M144.6,1014.6c367.9,48.8,735.8,97.6,1103.6,146.4" />
                            <path className="st7" d="M1241.2,1163.2c77.1-81.5,154.2-163.1,231.2-244.6" />
                            <g>
                                <g>
                                    <path className="st2" d="M1118.4,949c-37.5,29.3-74.2,59.5-110.3,90.5c-1.5,1.3,0.7,3.4,2.1,2.1c36.1-31,72.9-61.2,110.3-90.5
                                               C1122,950,1119.9,947.8,1118.4,949L1118.4,949z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1009.5,1041.1c11.8,2.5,23.8,2.5,35.9,2.6c13.6,0.2,26.4,1.6,39.4,5.4c24.4,7.1,48.3,11.8,73.8,11.2
                                               c1.9,0,1.9-3,0-3c-13.1,0.3-26.2-0.5-39.2-2.6c-12.2-2-23.8-5.6-35.7-8.9c-11.4-3.2-22.8-4.7-34.7-4.9c-13-0.3-26,0.1-38.8-2.6
                                               C1008.4,1037.8,1007.6,1040.7,1009.5,1041.1L1009.5,1041.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1120.6,951.2c39.6,3.2,78.9,9.5,117.5,18.9c1.9,0.5,2.7-2.4,0.8-2.9c-38.8-9.5-78.4-15.8-118.3-19
                                               C1118.7,948,1118.7,951,1120.6,951.2L1120.6,951.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1160.9,1058.5c27.9-27.6,54.7-56.4,80.1-86.4c1.2-1.5-0.9-3.6-2.1-2.1c-25.4,29.9-52.2,58.7-80.1,86.4
                                               C1157.4,1057.8,1159.5,1059.9,1160.9,1058.5L1160.9,1058.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1044.4,1029.2c34.4-4.2,69.2,0.1,101.5,12.7c1.8,0.7,2.6-2.2,0.8-2.9c-32.5-12.6-67.7-17-102.3-12.8
                                               C1042.5,1026.4,1042.5,1029.4,1044.4,1029.2L1044.4,1029.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1096.5,1018.2c21.2,1.6,42.1,4.9,62.8,9.8c1.9,0.5,2.7-2.4,0.8-2.9c-20.9-5-42.1-8.3-63.6-9.9
                                               C1094.5,1015.1,1094.6,1018.1,1096.5,1018.2L1096.5,1018.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1080.6,996.1c31.9,0.7,63.5,5.3,94.3,13.8c1.9,0.5,2.7-2.4,0.8-2.9c-31-8.5-62.9-13.2-95.1-13.9
                                               C1078.7,993.1,1078.7,996.1,1080.6,996.1L1080.6,996.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1120.2,986.9c17.2,2.4,34.2,5.4,51.2,9c1.9,0.4,2.7-2.5,0.8-2.9c-17-3.6-34-6.6-51.2-9
                                               C1119.1,983.7,1118.3,986.6,1120.2,986.9L1120.2,986.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1098.3,1054c-6.1,9.4-12.2,18.8-18.4,28.1c-0.6,0.9,0.2,2.4,1.3,2.3c49.3-5.6,99.3-2.4,147.5,9.4
                                               c1.9,0.5,2.7-2.4,0.8-2.9c-48.5-11.9-98.7-15.1-148.3-9.6c0.4,0.8,0.9,1.5,1.3,2.3c6.1-9.4,12.2-18.8,18.4-28.1
                                               C1102,1053.9,1099.4,1052.4,1098.3,1054L1098.3,1054z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1219.2,997.2c19.3-0.4,38.5-0.8,57.8-1.2c-0.5-0.6-1-1.3-1.4-1.9c-13.3,31.7-26.7,63.3-40,95
                                               c-0.7,1.8,2.2,2.6,2.9,0.8c13.3-31.7,26.7-63.3,40-95c0.4-0.9-0.6-1.9-1.4-1.9c-19.3,0.4-38.5,0.8-57.8,1.2
                                               C1217.3,994.3,1217.3,997.3,1219.2,997.2L1219.2,997.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1146.1,1071.7c5.1-1,10.2-1.2,15.4-0.8c1.9,0.1,1.9-2.9,0-3c-5.5-0.4-10.8-0.1-16.2,0.9
                                               C1143.4,1069.1,1144.2,1072,1146.1,1071.7L1146.1,1071.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1174.5,1072c15.7,0.6,31.4,2.1,47,4.5c1.9,0.3,2.7-2.6,0.8-2.9c-15.8-2.5-31.8-4-47.8-4.6
                                               C1172.6,1068.9,1172.6,1071.9,1174.5,1072L1174.5,1072z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1175.4,1053.2c3.7,0,7.4,0,11.2,0c1.9,0,1.9-3,0-3c-3.7,0-7.4,0-11.2,0
                                               C1173.5,1050.2,1173.5,1053.2,1175.4,1053.2L1175.4,1053.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1198.7,1052.9c12.3,0.3,24.5,1.5,36.7,3.5c1.9,0.3,2.7-2.6,0.8-2.9c-12.4-2-24.9-3.2-37.5-3.6
                                               C1196.8,1049.9,1196.8,1052.9,1198.7,1052.9L1198.7,1052.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1196.8,1035.8c14,0.2,27.9,1,41.8,2.6c1.9,0.2,1.9-2.8,0-3c-13.9-1.6-27.8-2.5-41.8-2.6
                                               C1194.9,1032.7,1194.9,1035.7,1196.8,1035.8L1196.8,1035.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1102.9,1069c6.7,0.2,13.3-0.6,19.9-2.2c1.9-0.5,1.1-3.4-0.8-2.9c-6.3,1.6-12.6,2.3-19.1,2.1
                                               C1101,1066,1101,1069,1102.9,1069L1102.9,1069z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M1150.4,912.3c-2.6,3.2-5.6,9.7-2.5,13.6c1.9,2.4,5.5,1.9,8.2,2c4.9,0.2,9.8,0.5,14.7,0.7
                                               c19.9,0.9,39.7,1.9,59.6,2.8c1.9,0.1,1.9-2.9,0-3c-14.7-0.7-29.3-1.4-44-2.1c-7.2-0.3-14.3-0.7-21.5-1c-3.5-0.2-7.2,0-10.7-0.5
                                               c-2.6-0.4-4.5-0.5-4.4-3.7c0.1-2.5,1.2-4.8,2.7-6.7C1153.7,912.9,1151.6,910.7,1150.4,912.3L1150.4,912.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1153.1,916.1c16.8,0.6,33.6,1.1,50.4,1.7c8.2,0.3,16.5,0.5,24.7,0.8c7.4,0.3,15.2,0.8,20.5,6.5
                                               c1.3,1.4,3.4-0.7,2.1-2.1c-4-4.4-9.4-6.1-15.2-6.9c-8.6-1.1-17.5-0.9-26.2-1.2c-18.8-0.6-37.6-1.3-56.3-1.9
                                               C1151.2,913.1,1151.2,916.1,1153.1,916.1L1153.1,916.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1229.4,931.5c7.7,0.1,15-2.3,21.2-6.9c1.5-1.1,0-3.7-1.5-2.6c-5.8,4.3-12.4,6.6-19.7,6.5
                                               C1227.4,928.5,1227.4,931.5,1229.4,931.5L1229.4,931.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1231.8,916.1c-4.2,2.8-6.1,8-4.7,12.8c0.5,1.8,3.4,1.1,2.9-0.8c-1-3.5,0.2-7.4,3.3-9.5
                                               C1234.9,917.6,1233.4,915,1231.8,916.1L1231.8,916.1z" />
                                </g>
                            </g>
                        </g>
                        <g id="color_1_">
                            <g>
                                <g>
                                    <path className="st3"
                                        d="M502.1,556.6c-2.5-5.2-9.6-5.6-13.4-1.7c-4.1,4.2-6,12.3-5.6,18c0.9,11.5,12.1,16.9,15.1,27.2
                                               c1.4,4.7,8.6,6.2,11,1.4c4-7.9,9.6-14.7,14.6-21.9c4.4-6.4,11.1-16.3,8.7-24.5c-1-3.5-4.1-6.5-7.9-6.9c-4.6-0.5-7.8,2.3-10.5,5.7
                                               c-2.7,3.5-5.1,7.1-7.1,11c-1.8,3.4-3.2,7.4-6.7,9.3c2.9,1.2,5.9,2.4,8.8,3.6c-1.3-4.3-2.6-8.6-3.8-12.8c-3.3,1.9-6.7,3.9-10,5.8
                                               c4.5,5.1,12.2,5.8,17.6,1.5c2.5-2,3.9-4.7,5.6-7.3c0.8-1.2,1.5-2.3,2.7-3.1c0.8-0.6,1.8-1,2.8-1.3c2-0.1,1.9-0.3-0.2-0.6
                                               c-1-1.7-2-3.5-3-5.2c0,0.2,0,0.5,0,0.7c1.5-1.9,2.9-3.9,4.4-5.8c-0.2,0.1-0.4,0.1-0.6,0.2c-7.3,2.3-4.2,13.9,3.2,11.6
                                               c0.2-0.1,0.4-0.1,0.6-0.2c2.7-0.8,4.2-3.1,4.4-5.8c0-0.2,0-0.5,0-0.7c0.1-2.1-1.3-4.1-3-5.2c-5-3.2-12.4-0.1-16.5,3.2
                                               c-2.3,1.9-3.9,4.1-5.5,6.6c-1,1.6-2.1,5-4,2.8c-4-4.5-11.7,0.3-10,5.8c1.3,4.3,2.6,8.6,3.8,12.8c1.1,3.8,5.4,5.5,8.8,3.6
                                               c5.1-2.7,7.9-7.4,10.4-12.4c1.5-2.9,3-5.7,4.9-8.3c0.3-0.5,3-3.3,2.9-3.8c-2.6-2.2-3.9-2.1-4.1,0.3c-0.2,0.8-0.5,1.5-0.8,2.2
                                               c-5,12.1-15,21.3-20.9,32.9c3.7,0.5,7.3,1,11,1.4c-1.9-6.5-6.2-11.2-10.3-16.4c-1.7-2.2-3.6-4.6-4.2-7.3
                                               c-0.4-1.9-0.2-3.6,0.3-5.4c0.3-1.2,0.7-2.3,1.2-3.4c1.7-2.4,0-3-5-1.7C495.1,569.6,505.4,563.5,502.1,556.6L502.1,556.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3" d="M508.6,589.4c8.8-10.6,16.5-21.9,22.9-34c3.6-6.8-6.8-12.9-10.4-6.1c-5.9,11.3-12.8,21.9-21,31.6
                                               C495.2,586.8,503.6,595.3,508.6,589.4L508.6,589.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8" d="M532.3,419.7c-1.6,10.5-1.2,20.8,1.6,31.1c1.5,5.6,10.2,3.2,8.7-2.4c-2.3-8.6-3-17.5-1.6-26.3
                                               C541.8,416.4,533.1,414,532.3,419.7L532.3,419.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8" d="M535.7,424.3c11,4.7,19.8-4.5,20.2-15.3c0-1-0.6-3.7-0.2-4.6c-0.2,0-0.4,0-0.5,0c0.9,0.3,1.6,0.8,2,1.7
                                               c1.6,2,2.4,4.6,4.1,6.6c3.5,4.1,8.3,4.7,13.4,5c2.5,0.1,5.5-0.2,7,2.2c1.5,2.3,0.7,6.2,0.5,8.8c-0.2,3.6-0.5,7.3-0.8,10.9
                                               c-0.1,0.9-1,4.3,0.3,3c-0.8,0.9-4.1,1.1-5.2,1.4c-6.6,1.5-13.4,2.1-20.1,1.7c-3.5-0.2-8.8,0.2-10.8-2.9
                                               c-1.8-2.9-1.9-7.3-1.4-10.6c-2.7,0.4-5.5,0.7-8.2,1.1c2.4,5.3,4.7,10.6,7.1,16c1.6,3.5,7.4,2.5,8.2-1.1
                                               c2.4-10.8-0.9-22.1,2.5-32.7c-2.5,0.7-5,1.3-7.5,2c2.9,3.5,2.2,11,2.9,15.3c0.8,5,2.2,9.6,4.5,14c2.1,3.8,8.7,2.2,8.4-2.3
                                               c-0.8-12-1.6-24.1-2.3-36.1c-2.9,0.4-5.9,0.8-8.8,1.2c4.2,11.3,4.8,23.1,1.5,34.7c2.2-0.9,4.4-1.8,6.6-2.7
                                               c-5.6-4.3-9.8-9.9-12.7-16.3c-1.9-4.1-8.6-2-8.4,2.3c0.2,4,0.3,8,0.5,12c3,0,6,0,9,0c-0.2-5,0-9.9,1-14.8
                                               c0.4-1.7,0.8-3.5,1.3-5.2c0.3-1.1,0.7-2.3,1.1-3.4c1.3-1.6,1.1-2.2-0.6-1.8c4.8,6.8,5.4,17,4.6,24.9c2.8-0.8,5.6-1.5,8.4-2.3
                                               c-2.3-4.7-3.8-9.7-4.4-14.9c-0.3-2.7-0.4-5.4-0.2-8.1c0-0.4,0.5-3.2,0.3-3.4c-0.7,0.1-1.4,0.1-2.1,0.2c0.4,0.8,0.8,1.5,1.2,2.3
                                               c4.6,9.4,6,20,4.9,30.4c2.8-0.8,5.6-1.5,8.4-2.3c-1.8-3.9-3.2-8-4-12.2c-0.3-1.8-0.5-3.6-0.3-5.4c0.1-1,0.3-2,0.6-3
                                               c1.1-1.2,1.2-1.7,0.3-1.4c-1.2-0.2-2.3-0.3-3.5-0.5c1.6,0.8,2.6,8.7,2.9,10.5c0.6,4.3,0.5,8.8-0.1,13.1c2.9,0,5.8,0,8.7,0
                                               c-1.1-3.2-1.9-6.5-2.4-9.8c-0.5-3.2-0.2-6.5-1.2-9.6c2.2,6.9,1.8,14.2-0.2,21c2.7-0.4,5.5-0.7,8.2-1.1c-1.2-2.6-2.2-5.4-2.7-8.3
                                               c-0.3-1.4-0.4-2.8-0.5-4.2c0-0.6-0.1-1.2,0-1.8c0.3-1.4-0.4-1.5-2-0.4c-0.9-0.7-1.8-1.4-2.7-2.1c5.7,9.8-5.7,20.7-15.4,19.3
                                               c1.1,1.4,2.2,2.9,3.3,4.3c-0.1-1.8-0.7-4.4-0.1-6.1c-0.7,0.9-1.4,1.8-2.1,2.7c-1.7,1.1,7.4-0.2,3.6-0.6c-5.2-0.5-6,7.5-1.2,8.8
                                               c4.8,1.3,9.2,3.9,14.3,3.3c4.4-0.5,7.7-3.1,9.5-7c1.9-4.1,2.3-8.9,1.7-13.4c-0.3-2.5-0.9-4.8-1.9-7c-0.4-1-0.9-2-1.4-3
                                               c-0.3-0.6-0.7-1.2-1.1-1.8c-0.4-0.9-0.6-0.7-0.4,0.6c2.8,0.8,5.6,1.5,8.4,2.3c0.1-1.9,0.3-3.7,0.4-5.6c-2.9,0.4-5.9,0.8-8.8,1.2
                                               c1.7,5,1.4,9.6-0.6,14.5c-2.2,5.4,6.5,7.7,8.7,2.4c2.5-6.3,2.7-12.9,0.6-19.3c-1.6-4.9-8.5-3.9-8.8,1.2c-0.1,1.9-0.3,3.7-0.4,5.6
                                               c-0.3,4.4,6.5,6.3,8.4,2.3c2.2-4.8,1.6-11-3.5-13.7c-4.4-2.3-10.3-0.3-12.4,4.2c-2.9,6.2,3.6,11.7,4.2,17.7
                                               c0.3,2.8,0.3,8.4-2.8,9.8c-3,1.3-8.7-2.2-11.5-3c-0.4,2.9-0.8,5.9-1.2,8.8c9.1,0.8,9.3-11.2,3-15.1c-3.1-1.9-7.3-3.4-10.6-1.4
                                               c-4.1,2.5-2.9,7.5-2.8,11.5c0.1,1.8,1.3,4.1,3.3,4.3c16,2.3,30.6-10.5,27.5-27.2c-0.6-3.3-1.9-7-5.6-7.7c-3.1-0.6-6.2,1.2-7.5,4
                                               c-3.3,7.1,0.6,17.6,3.6,24.1c1.7,3.7,7.2,2.4,8.2-1.1c2-6.7,2.6-13.6,1.4-20.5c-0.9-5.2-2.5-12-9-12.2c-14.5-0.3-8.5,23.5-6,31
                                               c1.3,3.8,7.9,4.7,8.7,0c1.2-7.7,1.1-15.4-0.9-23c-1.2-4.5-3.6-13.3-10.1-11c-5.2,1.9-6.8,9.4-6.8,14.3c0,7.2,2.6,14.3,5.6,20.6
                                               c2.1,4.5,8,1.7,8.4-2.3c0.9-8.4,0.2-16.9-2.2-25c-1.7-5.7-4.8-17.2-12.4-16.9c-7.4,0.2-7.8,9.7-7.8,15.1c0,8.7,2.2,17.1,6,25
                                               c2.1,4.4,8,1.7,8.4-2.3c0.8-7.1,0.1-14.3-2.1-21.1c-1.6-5-4.2-12.2-10.2-12.9c-6.6-0.7-8.9,6.9-10.4,11.8
                                               c-2.2,7.2-2.9,14.8-2.6,22.4c0.3,5.8,9.2,5.8,9,0c-0.2-4-0.3-8-0.5-12c-2.8,0.8-5.6,1.5-8.4,2.3c3.6,7.9,9.1,14.3,15.9,19.5
                                               c2.4,1.8,5.9-0.2,6.6-2.7c3.8-13,3.3-26.8-1.5-39.5c-1.7-4.4-9.2-4.2-8.8,1.2c0.8,12,1.6,24.1,2.3,36.1c2.8-0.8,5.6-1.5,8.4-2.3
                                               c-5.2-9.7-1.7-22.6-8.8-31.2c-2.3-2.8-6.6-1-7.5,2c-3.5,10.7-0.1,21.9-2.5,32.7c2.7-0.4,5.5-0.7,8.2-1.1
                                               c-2.4-5.3-4.7-10.6-7.1-16c-1.5-3.4-7.6-2.6-8.2,1.1c-1.1,7-0.6,16.8,5.5,21.6c2.8,2.2,6.5,2.4,10,2.8c4.7,0.5,9.5,0.7,14.3,0.4
                                               c4.6-0.3,9.1-1,13.6-2c3-0.7,7-1.2,9.1-3.6c2.7-2.9,2.2-7.6,2.5-11.3c0.3-4.4,0.8-8.7,0.9-13.1c0.1-7.2-2.8-13.5-10.2-15.3
                                               c-3-0.7-6.1-0.3-9.2-0.8c-4.8-0.7-5.3-4.8-7.9-8.2c-3.3-4.4-10.4-7.2-14.9-2.9c-2.9,2.8-2.3,6.4-2.2,10c0.1,3.2-1.5,11.3-6.6,9.1
                                               C535,414.3,530.4,422.1,535.7,424.3L535.7,424.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8"
                                        d="M270.7,474.1c7,6.4,13.6,13.3,19.3,20.9c3.8,5,7.7,15,14.5,16.4c4,0.8,7.2-1.3,10.2-3.7
                                               c4.1-3.3,8.1-6.7,12.2-10.1c4.1-3.4,8.1-6.7,12.2-10.1c2.9-2.4,6.2-4.6,6.4-8.7c0.2-3.9-2.3-6.8-4.6-9.7
                                               c-3.2-4.1-6.5-8.2-9.7-12.3c-3.2-4.1-6.5-8.2-9.7-12.3c-2.4-3-4.6-7-8.6-7.9c-3.6-0.8-6.9,1.3-9.9,2.9c-4,2.1-7.9,4.5-11.6,7
                                               c-7.3,5-14.1,10.7-20.3,17.1c-1.9,2-1.7,5.7,0.9,7.1c6.5,3.3,12.7,7.2,18.3,12c2.5,2.1,4.9,4.4,7.2,6.8c1.1,1.2,2.2,2.4,3.3,3.7
                                               c0.7,0.8,1.4,1.7,2.1,2.5c0.9,2.4,2.2,2.3,3.8-0.5c0.4,2.7,0.7,5.5,1.1,8.2c10.4-6.3,19.9-14.1,28.1-23.1
                                               c3.4-3.8-0.9-9.6-5.5-7.1c-7,3.8-14.2,6.9-21.7,9.3c1.7,2.2,3.4,4.4,5.1,6.6c4.2-6.7,9.4-12.5,15.5-17.6
                                               c-2.4-1.8-4.7-3.6-7.1-5.5c-5.5,8.2-12.4,15.4-20.3,21.3c2.2,1.7,4.4,3.4,6.6,5.1c2.6-9.4,7.9-17.7,15.8-23.6
                                               c-2.1-2.1-4.1-4.1-6.2-6.2c-4.5,8.9-10.8,16.5-18.9,22.3c1.5,0,3,0,4.5,0c0.3,0.3,0.5,0.7,0.8,1c0.2-0.8,0.4-1.6,0.6-2.3
                                               c0.3-1,0.6-2,1-3.1c0.9-2.7,2.1-5.2,3.4-7.7c2.5-4.7,5.6-8.8,9.3-12.6c-2.4-1.8-4.7-3.6-7.1-5.5c-5.3,10.9-12.6,20.4-21.8,28.4
                                               c2.6,1.1,5.1,2.1,7.7,3.2c1.1-11.8,6.2-22.6,14.5-31.1c-2.4-1.8-4.7-3.6-7.1-5.5c-4.1,8-10.1,14.3-17.9,18.8
                                               c2.3,1.3,4.5,2.6,6.8,3.9c-0.1-3.6,0.4-7.1,2.1-10.3c0.7-1.4,1.6-2.7,2.6-3.8c1.6-1.9,4.6-2.4,1.8-4.2c-1.3-1.6-2-1.5-2.1,0.5
                                               c-0.5,1-0.9,2-1.4,3c-0.8,1.5-1.6,2.9-2.4,4.3c-0.9,1.4-1.8,2.8-2.8,4.1c-0.8,1-1.5,2-2.4,3c-1.4,0.8-1.5,1.4-0.3,1.7
                                               c0.7,0.9,1.4,1.8,2.1,2.7c-1-3.2,2.3-9.2,3.8-11.9c0.9-1.5,6.1-10.1,8.4-9.6c1.1,0.2,2.6,5.8,3,6.8c0.8,2.2,1.5,4.5,2,6.8
                                               c0.5,2.3,1.1,4.7,1.1,7c0,2.7,0,2.8-2.2,0.9c-6.3-5.4-7-19.1,2.3-22.2c4.7-1.6,6.1,4.4,7.2,7.8c1.6,4.9,3.1,9.9,4.7,14.9
                                               c2.5-1.5,5-2.9,7.5-4.4c-5.6-5.5-8.6-12.5-8.8-20.4c-1.9,1.4-3.8,2.9-5.7,4.3c10.5,4,12.2,17.2,13.6,26.8
                                               c0.8,5.7,9.5,3.3,8.7-2.4c-2-13.6-5.7-27.8-19.9-33.1c-2.7-1-5.8,1.6-5.7,4.3c0.2,10.2,4.2,19.5,11.4,26.7c3.1,3.1,8.9,0,7.5-4.4
                                               c-1.8-5.7-3.4-11.4-5.4-17c-1.4-4-3.2-8.2-6.9-10.6c-8-5.3-17.4,0.7-21.5,8c-4.7,8.5-3.5,19.1,2.9,26.3
                                               c5.3,5.9,15.6,9.1,18.5-0.8c2.6-8.8-1.9-21.8-6.3-29.4c-6-10.2-15.9-2.5-21,4.1c-2.7,3.5-4.8,7.3-6.3,11.4
                                               c-1.4,3.8-3.7,10-0.9,13.7c6.6,8.6,16.1-6,19-10.8c2.5-4,6.1-9.5,5.5-14.5c-0.6-4.7-5-6.9-9.2-5.1c-9.2,4-13.9,16.3-13.5,25.6
                                               c0.1,3.4,3.7,5.6,6.8,3.9c9-5.2,16.3-12.8,21.1-22c2.3-4.4-3.4-9.2-7.1-5.5c-10,10.2-15.8,23.2-17.1,37.4c-0.4,4.2,5,5.5,7.7,3.2
                                               c9.7-8.4,17.6-18.7,23.2-30.2c2.1-4.4-3.3-9.3-7.1-5.5c-5.8,6-10.4,12.9-13.6,20.6c-2.1,5.1-6.5,14.8,0.3,18.3
                                               c1.4,0.7,3.2,0.9,4.5,0c9.4-6.7,17-15.2,22.2-25.5c1.8-3.6-2.2-9.1-6.2-6.2c-9.8,7.4-16.7,17.2-19.9,29c-1,3.5,3.3,7.5,6.6,5.1
                                               c9.3-6.8,17.1-14.9,23.6-24.5c3-4.5-3.4-8.5-7.1-5.5c-6.7,5.5-12.3,12-16.9,19.4c-2.1,3.3,1.4,7.8,5.1,6.6
                                               c8.3-2.6,16.3-6,23.9-10.2c-1.8-2.4-3.6-4.7-5.5-7.1c-7.7,8.5-16.5,15.8-26.3,21.7c-3.6,2.2-2.4,6.8,1.1,8.2
                                               c4.2,1.7,8.7-1.7,8.9-6c0.3-5.2-5.1-10.1-8.3-13.5c-8.1-8.8-17.8-16-28.4-21.3c0.3,2.4,0.6,4.7,0.9,7.1c4.7-4.8,9.7-9.2,15-13.2
                                               c4.8-3.6,10.8-8.4,16.5-10.3c1.7-0.6,1.6-0.9,3,0.5c1.4,1.4,2.5,3.1,3.6,4.6c2.2,2.8,4.5,5.7,6.7,8.5c2.4,3.1,4.9,6.2,7.3,9.3
                                               c1.3,1.7,6.6,6.8,6.2,8.7c-0.4,2.2-5.9,5.4-7.7,6.9c-2.8,2.3-5.6,4.6-8.4,6.9c-2.8,2.3-5.6,4.6-8.4,6.9c-1.2,1-3.8,3.9-5.9,2.8
                                               c-0.8-0.4-2.2-3.4-2.7-4.1c-7.3-11-16-20.9-25.7-29.8C272.8,463.9,266.4,470.2,270.7,474.1L270.7,474.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8" d="M275.5,466.1c3.6,7,26.2,46,35.8,32.7c3.4-4.7-4.4-9.2-7.8-4.5c-0.2,0.2-0.3,0.5-0.5,0.7
                                               c1.3-0.7,2.6-1.5,3.9-2.2c-1.7,0.2-5.8-5.5-6.9-6.8c-2.2-2.5-4.4-5-6.4-7.7c-3.9-5.3-7.3-10.9-10.3-16.7
                                               C280.7,456.4,272.9,461,275.5,466.1L275.5,466.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3" d="M454.4,342.6c-10.7-7.8-14.8-23.8-8.1-35.5c3.3-5.8,9.3-10.5,16.3-9.7c7.2,0.8,10.7,6,11.2,12.8
                                               c0.3,3.8-0.1,7.7-0.2,11.5c-0.2,3.6,0.2,7.8-0.8,11.3c-1,3.5-4.6,5.4-6,8.9c-1.2,3,0.1,5.6-3.4,6.6c-1,0.3-2.4,0.2-3.3,0.5
                                               c-1.6-0.2-1.6,0.1,0,0.8c-0.4-1.3-0.7-2.5-1.1-3.8c-1.3-4.4-2.6-8.9-3.9-13.3c-2.2-7.4-6.8-18.3-1-25.2c-2.4,0.3-4.8,0.6-7.3,0.9
                                               c3.5,2.6,2.2,13.2,2.4,16.8c0.3,7.5,0.6,15,0.9,22.5c0.3,7.9,11.2,7.6,12,0c0.8-7.9-0.3-15.7-0.6-23.7c-0.2-3.4-0.2-6.9,0.3-10.3
                                               c0.2-1.7,0.6-3.3,1.1-4.9c0.3-1.1,0.8-2.2,1.3-3.2c1.1-1.7,1.1-1.9,0.1-0.6c-1.5,0.2-3.1,0.4-4.6,0.6c-0.3-0.1,1.1,3.6,1.3,4.4
                                               c0.4,1.5,0.6,3.1,0.8,4.6c0.4,4.2,0.4,8.5,0.2,12.7c-0.1,1.9-0.3,3.8-0.6,5.8c-0.2,1.2-0.4,2.4-0.6,3.6c-1,2.4,0.7,3,5.1,1.5
                                               c0.4-0.8-0.7-4-0.8-4.9c-0.2-2.1-0.3-4.3-0.2-6.4c0.1-4.1,0.6-8,1.6-12c0.3-1.2,0.6-2.3,1-3.5c1.5-2.6-0.1-3.5-4.8-2.6
                                               c-0.4,0.7,1.2,4.5,1.4,5.2c0.9,4.2,1.1,8.5,0.7,12.8c-0.2,2-0.4,8.5-2.7,9c-7.5,1.8-4.3,13.4,3.2,11.6c7.9-1.9,10.1-9.9,11.1-17
                                               c1.1-8.4,0.7-17.9-2.5-25.8c-3.1-7.6-12.2-7.7-16.2-0.7c-4,7-4.9,16.9-4.8,24.8c0.1,6.4,0.2,17.4,7.9,19.7
                                               c8.5,2.5,11.5-6.7,12.4-13.1c1.2-8.4,1.5-17,0.1-25.4c-1-6.2-5-18.1-13.5-15.8c-8.2,2.1-9.8,16.4-10.2,23.2
                                               c-0.5,10.2,1.7,20.3,0.7,30.5c4,0,8,0,12,0c-0.4-10.1-0.8-20.2-1.3-30.3c-0.3-7.7-1.5-14.5-8-19.3c-1.9-1.5-5.7-0.9-7.3,0.9
                                               c-6.6,7.8-7.1,17.4-4.8,27c1.2,5.2,2.9,10.4,4.4,15.5c1.4,4.8,2.2,10.6,4.8,14.8c4.1,6.8,15.5,5.2,21.4,1.7
                                               c3.1-1.8,5.2-4.8,6-8.3c0.5-2.2,0.3-3.1,1.7-4.9c1.6-2,3.2-3.6,4.2-6c1.7-3.8,1.8-8,2-12c0.2-5,0.7-10.1,0.6-15.2
                                               c-0.2-8.9-3.1-17.3-10.8-22.4c-14.8-9.8-33.4-0.7-40.4,14.1c-8.2,17.5-1.6,38.2,13.7,49.4C454.6,357.5,460.6,347.1,454.4,342.6
                                               L454.4,342.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3" d="M453.8,369.1c2,3.2,5.6,4.4,9.2,3.3c1.6-0.5,3-1.4,4.2-2.5c0.3-0.3,0.7-0.6,1-1c0.3-0.3,0.2-0.3-0.2,0.1
                                               c1.9-1,0.4-1.3-4.4-1.1c2.4,2,6.2,2.5,8.5,0c2.1-2.2,2.5-6.3,0-8.5c-2.9-2.5-6.6-2.9-10-0.9c-1.1,0.6-2.2,1.5-3,2.5
                                               c-0.7,0.9,0.9-0.6-0.2,0.2c0.2-0.1,0.4-0.2,0.6-0.2c-2.1,0.5-0.6,1.2,4.5,2.1c-1.7-2.7-5.4-4-8.2-2.2
                                               C453.3,362.6,452,366.3,453.8,369.1L453.8,369.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3" d="M456.6,377c0,0.3,0,0.6,0,0.9c0,1.5,0.7,3.2,1.8,4.2c1,1,2.7,1.8,4.2,1.8c1.6-0.1,3.2-0.6,4.2-1.8
                                               c1.1-1.2,1.8-2.6,1.8-4.2c0-0.3,0-0.6,0-0.9c0-1.5-0.7-3.2-1.8-4.2c-1-1-2.7-1.8-4.2-1.8c-1.6,0.1-3.2,0.6-4.2,1.8
                                               C457.3,373.9,456.6,375.3,456.6,377L456.6,377z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3"
                                        d="M397.2,266.4c26.3-31.3,51.5-63.5,75.7-96.6c-2.3,0.9-4.5,1.8-6.8,2.8c2.3,0.7,4,1.9,5.7,3.6
                                               c-0.3-2.4-0.6-4.8-0.9-7.3c-23.1,33-46.1,65.9-69.2,98.9c2.7-0.7,5.5-1.4,8.2-2.2c-2-1.2-4-2.4-6-3.7c0.7,2.7,1.4,5.5,2.2,8.2
                                               c10.5-13.7,20.1-27.9,28.8-42.8c3.9-6.7-6.5-12.7-10.4-6.1c-8.7,14.8-18.3,29.1-28.8,42.8c-2,2.6-0.3,6.7,2.2,8.2
                                               c2,1.2,4,2.4,6,3.7c2.9,1.8,6.4,0.5,8.2-2.2c23.1-33,46.1-65.9,69.2-98.9c1.5-2.2,0.8-5.5-0.9-7.3c-3.1-3.2-6.8-5.4-11-6.7
                                               c-2.7-0.9-5.2,0.6-6.8,2.8c-23.5,32.2-48.1,63.6-73.8,94.1C383.8,263.8,392.3,272.4,397.2,266.4L397.2,266.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3"
                                        d="M329.9,606.4c0-0.3,0-0.6,0-0.9c0-7.7-12-7.7-12,0c0,0.3,0,0.6,0,0.9C317.9,614.2,329.9,614.2,329.9,606.4
                                               c0-0.3,0-0.6,0-0.9c0-7.7-12-7.7-12,0c0,0.3,0,0.6,0,0.9C317.9,614.2,329.9,614.2,329.9,606.4L329.9,606.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st3"
                                        d="M234.1,522.7c7.7,0,7.7-12,0-12C226.4,510.7,226.4,522.7,234.1,522.7L234.1,522.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8"
                                        d="M261.2,528c5.8,0,5.8-9,0-9C255.4,519,255.4,528,261.2,528L261.2,528z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8"
                                        d="M628.7,299.4c5.8,0,5.8-9,0-9C622.9,290.4,622.9,299.4,628.7,299.4L628.7,299.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st8" d="M685.1,435.4c0,0.6,0,1.1,0,1.7c0,1.2,0.5,2.4,1.3,3.2c0.8,0.8,2.1,1.4,3.2,1.3c1.2-0.1,2.4-0.4,3.2-1.3
                                               c0.8-0.9,1.3-2,1.3-3.2c0-0.6,0-1.1,0-1.7c0-1.2-0.5-2.4-1.3-3.2c-0.8-0.8-2.1-1.4-3.2-1.3c-1.2,0.1-2.4,0.4-3.2,1.3
                                               C685.6,433.1,685.1,434.2,685.1,435.4L685.1,435.4z" />
                                </g>
                            </g>
                        </g>
                        <g id="doodles">
                            <g>
                                <g>
                                    <path className="st2" d="M648.8,364c17,2.7,40.8,10.6,34.5,32.8c-2.9,10.2-10.9,17.5-18.8,24c-7.8,6.3-15.5,12.5-22.6,19.6
                                               c-6.8,6.8-13,14.2-18.7,22c-4.5,6.2-10.7,13.9-10,22.2c0.6,7.4,7.1,9.2,13,11.5c8.5,3.3,17.5,9.1,20.8,18
                                               c4.4,12-4.8,22.6-13.5,29.7c-7.8,6.4-16.3,11.9-21.6,20.7c-1,1.7,1.6,3.2,2.6,1.5c9.5-16,33.7-22.4,36.4-42.6
                                               c1.1-7.9-2.5-15.3-8.2-20.6c-2.8-2.7-6.1-4.9-9.5-6.8c-4.1-2.2-8.9-3.1-12.9-5.4c-11.2-6.5,2.8-23.1,7.6-29.4
                                               c6.9-9.1,14.6-17.5,23.1-25.1c13.6-12.4,34.9-23.4,36.4-44.1c1.5-21-21-28.1-37.6-30.7C647.7,360.8,646.9,363.7,648.8,364
                                               L648.8,364z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M353.4,360.8c-17.8,21.9-37.9,41.8-60.1,59.2c-21.8,17.1-45.5,30.7-69.6,44.2
                                               c-22.2,12.5-44.6,25.3-63.6,42.6c-8.5,7.7-17.9,16.7-22.7,27.4c-3.7,8.3-4,19.1,5.5,23.4c5.2,2.3,11.3,1.5,16.7,0.6
                                               c8.8-1.5,17.5-3.4,26.2-5.1c8.9-1.7,17.8-3.5,26.7-5.2c8.5-1.6,24.4-3.1,24.8,9.6c0.1,1.9,3.1,1.9,3,0
                                               c-0.4-11.8-13-14.8-22.7-13.6c-14.8,1.9-29.4,5.6-44.1,8.4c-10.3,2-37.8,10.3-35.8-9.4c1.3-12.7,13.3-23.7,22-32
                                               c18.4-17.4,40.5-30.3,62.5-42.7c23.1-13,46.1-25.8,67.3-41.8c24.4-18.5,46.5-39.9,65.8-63.6C356.7,361.4,354.6,359.3,353.4,360.8
                                               L353.4,360.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M194.3,566.2c-8.8,10.6-19.1,23.1-17.5,37.8c1.2,11.3,9.3,21.9,21.5,21.1c7.8-0.5,14.7-4.8,21.5-8.1
                                               c8.5-4.1,17.2-7.8,26.2-10.5c8.9-2.6,18.2-4.3,27.5-4.5c7.4-0.1,15.8,0.4,22,5c9.4,6.8,3.6,17-1.4,24.6
                                               c-4.9,7.3-9.2,14.8-13,22.7c-3.4,7.2-6.4,14.7-8.8,22.3c-1.8,5.7-3.9,12.4,0.5,17.4c9.4,10.9,23.7,1.6,31.6-5.8
                                               c12.1-11.3,25.2-20.7,40.3-27.7c16.9-7.8,35.3-12.4,53.8-13.4c9.4-0.5,18.9-0.2,28.2,1.3c8.3,1.3,17.6,3.9,21.5,12.2
                                               c0.8,1.7,3.4,0.2,2.6-1.5c-5.4-11.7-20.2-13.5-31.6-14.6c-14.9-1.4-30.1-0.3-44.7,2.9c-14.6,3.2-28.9,8.6-41.9,16
                                               c-6.4,3.6-12.4,7.7-18.2,12.2c-6.5,5.1-12,11.4-18.6,16.1c-5.9,4.2-18.5,7.3-22-1.9c-1.7-4.5,0.5-9.7,1.9-14.1
                                               c1.5-4.6,3.2-9.1,5.1-13.5c3.3-7.8,7.2-15.3,11.6-22.5c3.9-6.3,9.7-12.7,11.1-20.2c2.3-12.2-9.7-18.5-20-20
                                               c-15.3-2.1-31.5,1.4-45.9,6.6c-8.8,3.2-17.1,7.3-25.5,11.4c-8.7,4.3-19.5,7.6-26.9-0.9c-13.8-15.8,0.7-35.7,11.2-48.4
                                               C197.7,566.8,195.6,564.7,194.3,566.2L194.3,566.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M251.8,616.8c9.4-3,17.7,6.2,17.9,15.1c0.2,9.4-6,17.5-12.2,23.9c-1.4,1.4,0.8,3.5,2.1,2.1
                                               c7.6-7.8,15.5-18.9,12.5-30.5c-2.4-9.1-11.6-16.5-21.1-13.5C249.2,614.5,249.9,617.4,251.8,616.8L251.8,616.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M601.1,358.8c4.6,3.8,10,4.6,15.7,5.8c6.2,1.3,11.6,3.7,15.9,8.5c8.1,9.1,10.6,22.8,4.6,33.6
                                               c-0.9,1.7,1.6,3.2,2.6,1.5c5.8-10.4,4.6-23.2-1.8-33c-3-4.6-7.2-8.6-12.3-11c-7.2-3.4-16.2-2.3-22.6-7.5
                                               C601.7,355.5,599.6,357.6,601.1,358.8L601.1,358.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M234.4,571.8c5.7,15.3,23.8,11.4,34.9,5.4c7.1-3.8,13.7-8.7,19.5-14.3c6.2-6.1,11.2-13.3,17.3-19.5
                                               c6.7-6.8,16.1-13,26.2-11.3c9.1,1.5,14.6,9.6,20.4,15.8c7.6,8,22.9,16.2,30.6,3.5c1-1.7-1.6-3.2-2.6-1.5
                                               c-4.9,8-15.3,5.2-21.3,0.4c-5.4-4.4-9.3-10.4-14.6-14.9c-10-8.6-22.5-7.8-33.1-0.6c-11.7,7.9-18.8,21-29.5,30.1
                                               c-6.3,5.3-13.6,10.2-21.4,13.1c-8.3,3.1-19.9,2.9-23.5-6.9C236.6,569.2,233.7,570,234.4,571.8L234.4,571.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M259.4,478.6c12.8,11.1,23.5,24.3,31.8,39.1c0.9,1.7,3.5,0.2,2.6-1.5c-8.4-15.1-19.2-28.4-32.3-39.7
                                               C260.1,475.2,257.9,477.4,259.4,478.6L259.4,478.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M262,475.4c10.8-14.3,26.1-28.9,43.1-35.4c2.7-1,5.8-1.9,8.7-1.2c3.6,0.8,5.7,3.9,7.8,6.7
                                               c3,3.9,5.9,7.9,8.8,11.9c5.7,8,11.1,16.3,16.2,24.7c1,1.6,3.6,0.1,2.6-1.5c-6.4-10.5-13.3-20.8-20.6-30.7
                                               c-5.5-7.5-10.5-16.3-21.2-13.8c-9.3,2.2-18.2,9-25.5,14.7c-8.5,6.7-16,14.5-22.6,23.1C258.3,475.4,260.9,476.9,262,475.4
                                               L262,475.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M293,519c20.4-8.6,38.6-21.2,53.7-37.4c1.3-1.4-0.8-3.5-2.1-2.1c-14.7,15.8-32.5,28.3-52.4,36.6
                                               C290.4,516.9,291.2,519.8,293,519L293,519z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M273.6,477.4c11.4-10.3,24.1-18.8,38.1-25.3c1.7-0.8,0.2-3.4-1.5-2.6c-14.2,6.6-27.1,15.3-38.7,25.8
                                               C270,476.5,272.2,478.7,273.6,477.4L273.6,477.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M281.9,482.4c6.2-4.5,12.4-8.9,18.6-13.4c1.6-1.1,0.1-3.7-1.5-2.6c-6.2,4.5-12.4,8.9-18.6,13.4
                                               C278.8,481,280.3,483.6,281.9,482.4L281.9,482.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M286.1,489.3c10.4-7.3,20.8-14.7,31.2-22c1.6-1.1,0.1-3.7-1.5-2.6c-10.4,7.3-20.8,14.7-31.2,22
                                               C283,487.8,284.5,490.4,286.1,489.3L286.1,489.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M294.9,497.5c6.4-5.2,13.1-9.8,20.1-14.1c1.7-1,0.1-3.6-1.5-2.6c-7.3,4.3-14.2,9.2-20.7,14.5
                                               C291.3,496.6,293.4,498.7,294.9,497.5L294.9,497.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M299.5,505.7c9.4-7.7,19.6-14.1,30.5-19.4c1.7-0.8,0.2-3.4-1.5-2.6c-11.2,5.4-21.5,12.1-31.1,19.9
                                               C295.9,504.8,298,506.9,299.5,505.7L299.5,505.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M505.1,568.5c4.9-6.2-0.9-13.8-7.4-15.6c-7.8-2.2-16.7,1.5-20.6,8.5c-4.4,7.8-2.6,18,3.1,24.6
                                               c7.3,8.6,26.3,13.8,22.9,28c-0.5,1.9,2.4,2.7,2.9,0.8c3.9-16.2-15.6-21.2-23.7-30.9c-5.7-6.9-6.7-18.5,0.5-24.8
                                               c2.9-2.5,7-4,10.8-3.8c4.5,0.2,14.2,5.1,9.5,11.2C501.8,567.9,503.9,570,505.1,568.5L505.1,568.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M506.7,567.9c0.8-5.4,3.3-10.2,7.3-13.9c1.9-1.8,4.1-3.2,6.5-4.3c1.2-0.5,2.5-1,3.8-1.3
                                               c2.8-0.6,3.4-0.6,5.7,1.4c2.3,2.1,2.4,2.7,2.3,5.6c0,1.1-0.2,2.2-0.3,3.2c-0.3,2-0.9,3.9-1.6,5.8c-1.5,4.1-3.8,7.9-6.2,11.5
                                               c-8.6,12.8-20.9,24.5-21.6,40.8c-0.1,1.9,2.9,1.9,3,0c0.9-21.9,23.6-34.5,28.8-54.9c1-3.9,1.8-8.9,0.1-12.7
                                               c-2.1-4.4-6.7-4.6-10.9-3.6c-10.2,2.4-18,11.3-19.6,21.6C503.5,569,506.4,569.9,506.7,567.9L506.7,567.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M523.7,416.9c-1.8,9.7-2.2,19.9,0,29.6c2.1,9.3,8.8,7.3,16.5,7.8c17.5,1.2,40.7,12.3,52.2-7.5
                                               c1-1.7-1.6-3.2-2.6-1.5c-9.8,16.9-30.4,8.6-45.4,6.5c-3.5-0.5-6.8-0.5-10.3-0.4c-5.4,0-7-2.6-7.8-7.4c-1.5-8.7-1.3-17.5,0.4-26.2
                                               C526.9,415.8,524,415,523.7,416.9L523.7,416.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M524.1,421.7c5.4-3.3,10.1-3.9,16.2-4.9c2.6-0.4,4.3-1.4,4.7-4.1c1.1-6.8-1.8-13.8,3.6-19.6
                                               c6-6.5,14.1-0.7,16.6,5.9c1.2,3.1,1.9,6.7,4.3,9.2c3.1,3.3,7.2,2.4,11.1,3.5c15.1,4.1,10.6,23.6,7.4,34.1
                                               c-0.6,1.8,2.3,2.6,2.9,0.8c3.4-10.9,6.8-24.5-2.3-33.6c-1.5-1.5-3.2-2.7-5.1-3.5c-2.9-1.2-6.1-0.6-9.1-1.7
                                               c-5.8-2.1-5.7-10.7-9.2-15.1c-6.3-7.8-17.1-6.7-21.4,2.4c-1.2,2.5-1.6,5.1-1.8,7.8c-0.2,3.2,1.3,9.6-2.1,10.9
                                               c-2.4,1-6.1,0.6-8.7,1.4c-3.1,0.9-5.9,2.3-8.6,3.9C521,420.2,522.5,422.8,524.1,421.7L524.1,421.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M536,418.6c-1.5,11.8,0.5,23.5,5.7,34.2c0.8,1.7,3.4,0.2,2.6-1.5c-5-10.2-6.8-21.4-5.3-32.7
                                               C539.3,416.7,536.3,416.7,536,418.6L536,418.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M439.8,352.1c-12.9-6-20.6-19.9-19.9-34.1c0.8-14.5,10.6-27.7,23.1-34.6c7.8-4.3,16.1-5.6,24-1
                                               c7.2,4.1,12.3,11.2,15.2,18.8c5.2,13.8,3.3,29.5-4.9,41.8c-1.1,1.6,1.5,3.1,2.6,1.5c13.6-20.3,10.5-55.6-14.4-66.1
                                               c-12.5-5.3-25.8,1.8-34.8,10.6c-8.8,8.6-14.5,20.9-13.8,33.3c0.8,13.7,8.8,26.6,21.3,32.5C440,355.5,441.5,352.9,439.8,352.1
                                               L439.8,352.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M438.4,355.7c-2.6,2.8-1.9,7.1,1.3,9.2c5.2,3.4,14.2,0.3,19.7-1c5.9-1.4,14.8-2.7,18.8-7.9
                                               c2.4-3.2,2.4-7.6-0.7-10.3c-1.5-1.2-3.6,0.9-2.1,2.1c4.6,3.9-1.4,8.4-5,9.8c-3,1.1-6.1,2-9.2,2.8c-2.9,0.7-5.8,1.4-8.7,1.9
                                               c-1.8,0.3-3.8,0.8-5.6,0.9c-2.6,0.1-10.1-1.3-6.3-5.3C441.9,356.4,439.8,354.3,438.4,355.7L438.4,355.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M444.2,366.4c0.4,5.9,5.4,9.7,11.2,10.1c8,0.5,14-6.2,18.7-11.7c1.2-1.5-0.9-3.6-2.1-2.1
                                               c-3.3,3.9-7,8.4-11.9,10.1c-5,1.8-12.4-0.2-12.8-6.4C447.1,364.5,444.1,364.5,444.2,366.4L444.2,366.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M452.3,377.6c-0.8,4.1,1.7,8.4,6,9.1c4.4,0.7,8.2-3.2,8.3-7.4c0-1.9-3-1.9-3,0c0,2.5-2.4,5-5.1,4.4
                                               c-2.3-0.5-3.8-3.1-3.3-5.3C455.6,376.5,452.7,375.7,452.3,377.6L452.3,377.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M431.8,314.3c8.6,5.5,15.2,13.2,19.4,22.5c0.8,1.8,3.4,0.2,2.6-1.5c-4.4-9.8-11.4-17.8-20.5-23.6
                                               C431.6,310.7,430.1,313.3,431.8,314.3L431.8,314.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M447.2,307c1.9,14.4,3.9,28.8,5.8,43.1c0.3,1.9,3.1,1.1,2.9-0.8c-1.9-14.4-3.9-28.8-5.8-43.1
                                               C449.8,304.3,447,305.1,447.2,307L447.2,307z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M452.4,331.5c2.6-9.4,8.6-17,17.1-21.8c1.7-0.9,0.2-3.5-1.5-2.6c-9.1,5.1-15.7,13.5-18.5,23.6
                                               C449,332.6,451.9,333.4,452.4,331.5L452.4,331.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M343.4,588.6c4,8.7,8.3,17.3,13.1,25.6c2.4,4.1,5.6,11.9,11.5,9.4c1.8-0.8,0.2-3.3-1.5-2.6
                                               c-3.8,1.6-10.6-13.9-11.9-16.4c-3.1-5.7-5.9-11.6-8.6-17.5C345.2,585.3,342.6,586.8,343.4,588.6L343.4,588.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M346.1,590.4c8.5-6.6,16.8-13.4,25.4-19.8c7.5-5.7,15.6-11.2,24.8-13.8c9.9-2.9,18.5-0.6,25.1,7.4
                                               c5.6,6.8,9,15.2,12.8,23c0.8,1.7,3.4,0.2,2.6-1.5c-7.3-15-15.9-36.2-36.4-32.9c-10.2,1.6-19.4,7.2-27.7,13.2
                                               c-9.8,7.2-19.2,14.9-28.8,22.4C342.5,589.5,344.6,591.6,346.1,590.4L346.1,590.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M363.9,623.9c26.1-5.7,50.3-17.9,70.6-35.3c1.5-1.3-0.7-3.4-2.1-2.1c-19.9,17.1-43.7,28.9-69.3,34.6
                                               C361.2,621.5,362,624.4,363.9,623.9L363.9,623.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M388.2,595.7c-5.3-1.3-9.7-6.5-8.4-12.2c1.2-5.5,7.9-9.5,13.2-6.9c10.7,5.4,3.4,20.8-7.6,16.9
                                               c-1.8-0.6-2.6,2.3-0.8,2.9c6.2,2.2,13.7-0.8,16.1-7.1c2.4-6.2-1.5-13.7-7.6-15.9c-6.6-2.4-14.1,2.1-16,8.7
                                               c-2.2,7.4,3.2,14.7,10.3,16.5C389.3,599.1,390.1,596.2,388.2,595.7L388.2,595.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M441.5,519.8c-1.2-10.7-1.3-22.9,11.4-25.8c6.4-1.5,11.7-3.4,15.4-9.3c3.8-6,3.3-11.6,1.8-18.2
                                               c-1.1-5.2-1-9.7,3.2-13.6c3.4-3.1,7.9-4.3,12.3-2.9c1.8,0.6,2.6-2.3,0.8-2.9c-6.4-2-13.1,0.5-17.2,5.8
                                               c-6.3,8.3,1.1,16.7-1.4,25.6c-1.5,5.3-5.6,9.8-10.9,11.6c-4.3,1.4-8.9,1.3-12.5,4.3c-7.6,6.2-6.9,16.7-5.9,25.4
                                               C438.7,521.7,441.7,521.7,441.5,519.8L441.5,519.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M417.1,463.8c-3.1-2.5-0.6-8.1,3.4-6c1.6,0.8,2.6,2.7,1.7,4.4c-0.9,1.7-3.2,2.6-4.9,1.7
                                               c-1.7-0.9-3.2,1.7-1.5,2.6c3.1,1.5,6.9,0.4,8.8-2.4c2-3,0.7-6.8-2.2-8.6c-2.9-1.8-6.8-1.3-8.7,1.7c-1.7,2.8-1.3,6.7,1.3,8.8
                                               C416.5,467.1,418.6,465,417.1,463.8L417.1,463.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M553.9,308.8c-1.8-2.9-2.9-6.6-1.8-9.9c0.9-2.8,3.7-4.5,6.6-4.2c3.2,0.3,4.9,3.3,3.2,6.1
                                               c-1.5,2.6-4.6,3.9-7.5,3.5c-1.9-0.2-1.9,2.8,0,3c4.3,0.5,9-2.1,10.6-6.2c1.5-3.7-0.4-7.8-4.2-9c-3.6-1.2-8,0.2-10.2,3.3
                                               c-3.3,4.5-2,10.6,0.7,15.1C552.3,311.9,554.9,310.4,553.9,308.8L553.9,308.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M392.6,431.4c5.4,9.8,16.4,15.3,27.5,13.7c13.6-2,20.9-12.4,26.3-24c6.3-13.4,15-24.4,28.9-30.3
                                               c12.6-5.3,28.9-2.6,39.3-12.5c4-3.8,6.7-9.1,5.8-14.7c-0.7-4.3-3.3-7.9-2.3-12.4c1.2-4.9,5-8.5,9.8-9.5c1.9-0.4,1.1-3.3-0.8-2.9
                                               c-5.8,1.3-10.5,5.7-11.9,11.6c-0.8,3.2-0.2,6.1,0.8,9.2c1.6,5,2.2,9.1-1.1,13.7c-5.8,8.1-16,9.2-25.1,10.5
                                               c-8.1,1.2-15.7,3.4-22.7,7.6c-6.2,3.7-11.7,8.7-16,14.5c-4.7,6.4-7.3,13.9-11.1,20.8c-4.8,8.5-12.7,15-22.8,15.6
                                               c-9.2,0.5-17.8-4.4-22.3-12.5C394.3,428.2,391.7,429.7,392.6,431.4L392.6,431.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M371.7,267.4c-1.7,12.5-3.4,25-5,37.5c-0.3,1.9,2.6,2.7,2.9,0.8c1.7-12.5,3.4-25,5-37.5
                                               C374.9,266.3,372,265.5,371.7,267.4L371.7,267.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M371.2,306.6c11.8-8.5,23.6-17,35.4-25.5c1.6-1.1,0.1-3.7-1.5-2.6c-11.8,8.5-23.6,17-35.4,25.5
                                               C368.1,305.1,369.6,307.7,371.2,306.6L371.2,306.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M373.6,269.1c29.6-39.7,58.7-79.7,87.3-120.1c1.1-1.6-1.5-3.1-2.6-1.5c-28.6,40.4-57.7,80.5-87.3,120.1
                                               C369.9,269.1,372.5,270.6,373.6,269.1L373.6,269.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M410.3,276.6c27.2-36,53.6-72.6,79.2-109.8c1.1-1.6-1.5-3.1-2.6-1.5c-25.6,37.1-52,73.8-79.2,109.8
                                               C406.5,276.6,409.1,278.1,410.3,276.6L410.3,276.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M460,149c4.7-8.8,10.2-19.5,20.6-22.4c10.3-2.9,20.4,4.9,20.6,15.6c0,1.9,3,1.9,3,0
                                               c-0.1-10.3-8.8-19.6-19.4-19.3c-13.9,0.4-21.7,13.7-27.4,24.6C456.5,149.2,459.1,150.7,460,149L460,149z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M490.1,166.5c5.1-7.6,9.7-15.4,13.8-23.6c0.9-1.7-1.7-3.2-2.6-1.5c-4.1,8.2-8.7,16-13.8,23.6
                                               C486.5,166.5,489.1,168.1,490.1,166.5L490.1,166.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M457.8,153.9c11.5-7.8,26.4-3.6,32.1,9c0.8,1.8,3.4,0.2,2.6-1.5c-6.4-14.1-23.5-18.8-36.2-10.1
                                               C454.7,152.4,456.2,155,457.8,153.9L457.8,153.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M452.5,162.9c10-6.4,24.7-2.2,29.3,9c0.7,1.8,3.6,1,2.9-0.8c-5.4-12.8-21.9-18.3-33.7-10.8
                                               C449.4,161.4,450.9,164,452.5,162.9L452.5,162.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M371.8,270.5c5.5,3.3,11-3.9,16.7-3.8c4.3,0.1,8.3,2.1,10.8,5.7c1.8,2.6,2.7,5.1,5.6,6.6
                                               c1.7,0.9,3.2-1.7,1.5-2.6c-3.4-1.8-4.4-6.2-7.3-8.7c-2-1.7-4.4-2.9-7-3.5c-2.6-0.6-5.3-0.6-7.8,0c-3.2,0.8-7.9,5.5-10.9,3.7
                                               C371.7,266.9,370.2,269.5,371.8,270.5L371.8,270.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M388.2,265.1c20.2-26.6,40.5-53.3,60.7-79.9c1.2-1.5-1.4-3-2.6-1.5c-20.2,26.6-40.5,53.3-60.7,79.9
                                               C384.5,265.1,387.1,266.6,388.2,265.1L388.2,265.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M419.9,245.1c18.8-27.6,38.7-54.3,59.8-80.2c1.2-1.5-0.9-3.6-2.1-2.1c-21.2,26.1-41.4,53-60.3,80.8
                                               C416.3,245.2,418.9,246.7,419.9,245.1L419.9,245.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M370.6,297.1c1.9-1.6,4.7,0,4.2,2.5c-0.4,1.9,2.5,2.7,2.9,0.8c0.5-2.4-0.4-4.9-2.6-6.2
                                               c-2.2-1.3-4.8-0.8-6.7,0.8C367,296.2,369.1,298.3,370.6,297.1L370.6,297.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M371.5,296.7c-0.5,2.1-1.4,4-2.7,5.7c0.8,0.5,1.7,1,2.5,1.5c0.6-2.2,1.8-4.1,3.5-5.7
                                               c-0.8-0.1-1.6-0.2-2.4-0.3c0.5,1.1,0.1,2.3-0.9,2.9c0.7,0.3,1.5,0.6,2.2,0.9c-0.4-1.4-0.1-2.7,0.8-3.8c-0.6,0.1-1.2,0.2-1.8,0.2
                                               c0.8,0.4,0.7,1.5-0.1,1.9c0.6,0.1,1.2,0.2,1.8,0.2c-0.6-0.5-1.1-1.1-1.4-1.8c-0.1-0.3-0.2-0.6-0.3-1c0-0.2-0.2-2.3-0.5-1.7
                                               c-0.9,1.7,1.7,3.2,2.6,1.5c1.4-2.6-2.2-4.9-4.2-2.8c-2.1,2.3-0.2,6.1,1.8,7.8c0.5,0.5,1.2,0.6,1.8,0.2c2.8-1.4,2.8-5.6,0.1-7.1
                                               c-0.5-0.3-1.4-0.3-1.8,0.2c-1.6,1.9-2.2,4.3-1.6,6.7c0.2,0.9,1.4,1.4,2.2,0.9c2.5-1.5,3.2-4.5,2-7c-0.4-0.9-1.7-0.9-2.4-0.3
                                               c-2.1,1.9-3.5,4.3-4.2,7c-0.4,1.3,1.5,2.7,2.5,1.5c1.6-2.1,2.8-4.4,3.5-7C374.9,295.6,372,294.8,371.5,296.7L371.5,296.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M365.9,312.4c-2,10.8-6.5,20.7-13.6,29.1c-1.2,1.5,0.9,3.6,2.1,2.1c7.4-8.9,12.3-19.1,14.4-30.5
                                               C369.1,311.3,366.2,310.5,365.9,312.4L365.9,312.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M345,324.9c-10.4,7.7-21.4,14.6-32.8,20.7c-1.7,0.9-0.2,3.5,1.5,2.6c11.4-6.1,22.4-13,32.8-20.7
                                               C348.1,326.3,346.6,323.7,345,324.9L345,324.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M589.1,553.5c-2.7-4.2-7.2-2-9.6,1.2c-2.6,3.3-4.7,7.2-6.9,10.8c-1.7,2.8-3.7,5.9-4,9.4
                                               c-0.6,7,4.7,7.8,10,9.5c6.3,2.1,5.9,9.5,1.9,13.6c-3.2,3.3-7.9,4.7-12.1,6.1c-1.8,0.6-1,3.5,0.8,2.9c8.4-2.7,20.9-8.8,16.6-20
                                               c-0.7-1.8-1.9-3.3-3.5-4.3c-0.9-0.5-1.9-0.9-2.9-1.2c-2.9-0.9-3.7-0.6-6-3.1c-1.9-1.1-2.5-2.6-1.6-4.6c0.4-1.4,0.9-2.7,1.6-3.9
                                               c0.8-1.6,1.8-3.1,2.8-4.6c1-1.5,8-14.1,10.3-10.4C587.5,556.6,590.1,555.1,589.1,553.5L589.1,553.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M417.4,671.2c8.2-2.1,15.2,6,11.6,13.8c-0.8,1.7,1.8,3.3,2.6,1.5c4.7-10.1-4-21-14.9-18.2
                                               C414.7,668.8,415.5,671.7,417.4,671.2L417.4,671.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M570.6,506.5c-3.8-2.8-3.8-10.1,2-10.3c2.1-0.1,4.3,1.1,4.2,3.4c0,1.9-1.7,4.2-3.8,3.9
                                               c-1.9-0.3-2.7,2.6-0.8,2.9c3.7,0.6,6.9-2.1,7.5-5.7c0.7-3.7-2-6.9-5.6-7.4c-3.7-0.6-7.5,1.5-8.8,5.1c-1.3,4,0.5,8.3,3.8,10.7
                                               C570.6,510.2,572.1,507.6,570.6,506.5L570.6,506.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M152.6,494.1c1.4-5.6,6.2-8.8,10.7-11.9c4.7-3.3,9.5-6.7,14.3-10c9.5-6.7,19-13.3,28.5-20
                                               c1.6-1.1,0.1-3.7-1.5-2.6c-11.1,7.8-22.2,15.5-33.3,23.3c-7.5,5.2-19.2,10.7-21.6,20.4C149.2,495.2,152.1,496,152.6,494.1
                                               L152.6,494.1z" />
                                </g>
                            </g>
                        </g>
                        <g id="laptop">
                            <path className="st1" d="M823.5,1019.8C702,999,580.5,978.2,459,957.4c73.8-29.8,147.6-59.5,221.4-89.3
                               c115.4,25.7,230.8,51.4,346.1,77.1C958.9,970.1,891.2,994.9,823.5,1019.8z" />
                            <g>
                                <g>
                                    <path className="st2" d="M818.5,1021.3c0.4,1.2,0.7,2.5,1.1,3.7c0.5,1.6,2.9,1.3,2.9-0.4c0-1,0-1.9,0-2.9c0-1.9-3-1.9-3,0
                                               c0,1.2-0.2,2.3-0.7,3.4c1,0,1.9,0,2.9,0c-0.4-1.3-0.5-2.5-0.2-3.9c-0.9,0.1-1.8,0.2-2.7,0.4c0.3,0.3,0.3,0.5,0.1,0.8
                                               c0.9,0.1,1.8,0.2,2.7,0.4c-0.2-0.7-0.1-1.3,0.3-1.9c-0.9-0.3-1.9-0.5-2.8-0.8c-0.2,1.6-0.4,3.1-0.6,4.7c-0.1,0.8,0.8,1.5,1.5,1.5
                                               c0.9,0,1.4-0.7,1.5-1.5c0.2-1.6,0.4-3.1,0.6-4.7c0.2-1.5-2.1-1.9-2.8-0.8c-0.8,1.3-1,2.8-0.6,4.3c0.3,1.3,2.1,1.4,2.7,0.4
                                               c0.8-1.2,0.8-2.7-0.1-3.8c-0.7-0.9-2.5-1-2.7,0.4c-0.4,1.8-0.3,3.7,0.2,5.5c0.5,1.5,2.4,1.3,2.9,0c0.5-1.4,0.8-2.8,0.8-4.2
                                               c-1,0-2,0-3,0c0,1,0,1.9,0,2.9c1-0.1,2-0.3,2.9-0.4c-0.4-1.2-0.7-2.5-1.1-3.7C820.9,1018.6,818,1019.4,818.5,1021.3L818.5,1021.3
                                               z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M820.8,1028.5c16.1-8.3,33.7-12.7,51-17.6c8.5-2.4,16.9-5,25-8.4c7.8-3.2,15.3-7.2,23-10.8
                                               c17.1-8,35.6-13.2,53.5-19.1c18.6-6.2,37.1-12.4,55.7-18.5c1.8-0.6,1-3.5-0.8-2.9c-18.6,6.2-37.1,12.4-55.7,18.5
                                               c-17.6,5.8-35.7,11-52.6,18.7c-8.8,4-17.4,8.7-26.4,12.2c-8.2,3.2-16.6,5.7-25.1,8.1c-16.7,4.8-33.6,9.2-49.2,17.2
                                               C817.6,1026.8,819.1,1029.4,820.8,1028.5L820.8,1028.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M822.2,1027.9c69.1-23.9,138-48.1,206.9-72.7c5.4-1.9,3.1-10.6-2.4-8.7c-68.8,24.6-137.8,48.8-206.9,72.7
                                               C814.4,1021.1,816.7,1029.8,822.2,1027.9L822.2,1027.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M814.4,931.3c13,1.3,24.1,9.2,36.9,11.7c1.9,0.4,2.7-2.5,0.8-2.9c-13-2.6-24.3-10.5-37.7-11.8
                                               C812.5,928.2,812.5,931.2,814.4,931.3L814.4,931.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M852.2,941.6c7.9-4.3,15.3-9.4,22-15.4c1.4-1.3-0.7-3.4-2.1-2.1c-6.6,5.8-13.7,10.8-21.4,14.9
                                               C849,940,850.5,942.6,852.2,941.6L852.2,941.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M817.5,925.6c6.3-4.5,12.8-8.8,19.5-12.8c1.7-1,0.1-3.6-1.5-2.6c-6.7,4-13.2,8.2-19.5,12.8
                                               C814.4,924.2,815.9,926.8,817.5,925.6L817.5,925.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M837.5,913.3c11.4,4.3,22.8,8.5,34.3,12.8c1.8,0.7,2.6-2.2,0.8-2.9c-11.4-4.3-22.8-8.5-34.3-12.8
                                               C836.5,909.8,835.7,912.7,837.5,913.3L837.5,913.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M805.4,942.7c30.1,9.3,59.8,20,88.9,32.1c1.8,0.7,2.6-2.2,0.8-2.9c-29.1-12.1-58.8-22.8-88.9-32.1
                                               C804.3,939.2,803.5,942.1,805.4,942.7L805.4,942.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M808.3,966c18.7,9,37.6,17.5,56.7,25.8c1.8,0.8,3.3-1.8,1.5-2.6c-19.1-8.2-38-16.8-56.7-25.8
                                               C808.1,962.6,806.6,965.2,808.3,966L808.3,966z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M811,991.7c10.2,5.2,20.4,10.5,30.6,15.7c1.7,0.9,3.2-1.7,1.5-2.6c-10.2-5.2-20.4-10.5-30.6-15.7
                                               C810.8,988.2,809.3,990.8,811,991.7L811,991.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M816.8,992.6c14.1-12,28.9-23,44.3-33.2c1.6-1.1,0.1-3.7-1.5-2.6c-15.6,10.3-30.6,21.6-44.9,33.7
                                               C813.2,991.8,815.3,993.9,816.8,992.6L816.8,992.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M813.1,972.1c8-5.6,16-11.2,24-16.9c1.6-1.1,0.1-3.7-1.5-2.6c-8,5.6-16,11.2-24,16.9
                                               C810.1,970.6,811.6,973.2,813.1,972.1L813.1,972.1z" />
                                </g>
                            </g>
                            <path className="st1" d="M818.1,1026.7c-125.7-21.4-251.4-42.8-377.1-64.2c-11.8-89.3-23.7-178.5-35.5-267.8
                               c135.3,18.2,270.5,36.4,405.8,54.7C813.6,841.8,815.9,934.2,818.1,1026.7z" />
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M594.1,872.2c-10.9-3.4-16.8-12.6-17.7-23.7c-0.9-11.3,1.3-22.2,11.8-28.1c9.3-5.2,20.4-3.7,28.7,2.6
                                               c8.3,6.3,15.7,19.2,9,29.1c-1.1,1.6,1.5,3.1,2.6,1.5c7.2-10.6,0.4-24.6-8.4-31.9c-9.1-7.5-21.4-9.8-32.1-4.5
                                               c-12.4,6.1-15.6,18.5-14.6,31.4c1,12.5,7.6,22.8,19.9,26.6C595.1,875.7,595.9,872.8,594.1,872.2L594.1,872.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M596.9,873.3c-4.6-7.6-3.1-18.2,4.3-23.5c7.4-5.3,18-3.1,23.6,3.7c1.2,1.5,3.3-0.6,2.1-2.1
                                               c-6.6-7.9-19-10.1-27.6-4c-8.6,6.2-10.4,18.6-5,27.4C595.3,876.4,597.9,874.9,596.9,873.3L596.9,873.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M607.2,877.5c-4.7-5-4-14.3,2.3-17.9c6.2-3.6,14.6,0.5,16.6,7.1c0.6,1.8,3.5,1.1,2.9-0.8
                                               c-2.6-8.4-13.1-13.4-21-8.8c-7.9,4.6-8.9,16.2-2.9,22.6C606.4,881,608.5,878.9,607.2,877.5L607.2,877.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M614.9,884.5c3.9,1.4,8.4-0.9,9.9-4.6c1.6-3.7-0.5-7.7-4-9.3c-3.5-1.6-7.7-0.7-9.6,2.7
                                               c-1.8,3.3-1.2,7.8,1.8,10.2c1.5,1.2,3.6-0.9,2.1-2.1c-4-3.1-1.2-10.4,4.1-8.2c2.3,0.9,3.9,3.3,2.8,5.8c-1.1,2.4-3.9,3.6-6.3,2.7
                                               C613.9,881,613.1,883.9,614.9,884.5L614.9,884.5z" />
                                </g>
                            </g>
                            <g id="Layer_8">
                            </g>
                            <line className="st9" x1="811.3" y1="749.3" x2="820.1" y2="1024.8" />
                            <path className="st9" d="M811.9,749.1c2.7,90.6,5.4,181.2,8.1,271.8" />
                            <path className="st10"
                                d="M638.8,730.7c6.5,3.4,62.3,33.8,66.2,87.8c2.2,29.8-12.4,52.4-21.6,66.2c-49.6,74.6-147.7,87.8-174.3,90.6
                               c38.7,25.7,173.9,110.4,321.7,105.4c32.9-1.1,83.9-3.4,139.2-33.8c41.5-22.8,68.4-52.1,83.8-71.6c-9.5-5.9-18.9-11.7-28.4-17.6
                               c-69.8,23.9-139.7,47.8-209.5,71.6c-3.6-92.4-7.2-184.7-10.8-277.1C749.6,745.2,694.2,738,638.8,730.7z" />
                            <path className="st11"
                                d="M559.3,981.8c13,8.6,33.1,20.9,59.1,32.7c32.4,14.8,128.3,58.6,232.2,34.3c75.5-17.7,133.9-66.2,130.1-74.2
                               c-2.4-5-31.7,0.2-162.2,52.1c-2.1-42.3-4.2-84.7-6.2-127c-3.6,35.6-7.3,71.2-10.9,106.7C720.7,998.3,640,990,559.3,981.8z" />
                        </g>
                        <g id="color">
                            <path className="st8" d="M586.2,867.8c-7.3-8-9.7-19.2-6.1-29c3.7-9.9,13.5-18.4,25.2-18c10.3,0.4,18.6,7.6,21.6,14.4
                               c2.2,5.2,1.7,10,1.7,10c-0.3,2.7-1.2,4.8-1.9,6.1c-5.1-5.9-12.9-8.6-20.2-6.9c-9,2.1-12.9,9.8-13.3,10.5c-4.1,8.4-0.5,16.2,0,17.1
                               C590.8,870.5,588.5,869.1,586.2,867.8z" />
                        </g>
                        <g id="hand_L">
                            <path className="st1"
                                d="M1092.2,741.2c-5.4,4-11.2,9.4-16.5,16.5c-19.7,26.5-17,57.6-15.6,67.7c-17.5-3.6-46.9-7.1-81.6,0.9
                               c-20.7,4.8-37.3,12.3-49.5,19.1c1.9,1.2,4.5,2.6,7.8,3.5c18.7,5.1,38.4-10.6,39-9.5c0.6,1-22,11.6-20.8,20.8
                               c0.8,6.2,11.9,9.5,23.4,13c9.7,2.9,18.9,5.7,31.2,4.3c16.9-1.9,28.6-10.4,33.8-14.8c5,2.5,12.4,5.8,21.7,8.7
                               c17.1,5.2,40.7,12.4,56.4,2.6c10.2-6.4,13.3-21.4,19.1-51.2c7.4-38.2,11.1-57.3,0.9-69.4C1128,737.3,1100.9,740,1092.2,741.2z" />
                            <g>
                                <g>
                                    <path className="st2" d="M1056.7,826.4c12.1,4.5,22.1,12.7,29,23.6c1,1.6,3.6,0.1,2.6-1.5c-7.3-11.6-18-20.2-30.8-25
                                               C1055.7,822.9,1054.9,825.8,1056.7,826.4L1056.7,826.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M992.4,846.2c-11.9,2-21.5,10-25.8,21.2c-0.7,1.8,2.2,2.6,2.9,0.8c3.9-10.2,12.9-17.3,23.7-19.1
                                               C995.1,848.8,994.3,845.9,992.4,846.2L992.4,846.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M968,869.3c6.2,5.9,14.8,7.9,22.9,5.3c1.8-0.6,1-3.5-0.8-2.9c-7.1,2.3-14.6,0.7-20-4.5
                                               C968.7,865.8,966.6,867.9,968,869.3L968,869.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1006.3,856.1c-4.3,2.6-8.3,5.6-12.1,8.8c-2.5,2.2-5.3,4.8-4.7,8.4c1.5,9.5,12.9,7.4,19.1,5
                                               c1.8-0.7,1-3.6-0.8-2.9c-2.7,1-5.7,2.3-8.6,2.2c-4.7-0.2-8.9-4.7-4.9-8.8c3.8-4,8.8-7.2,13.5-10.2
                                               C1009.5,857.7,1008,855.1,1006.3,856.1L1006.3,856.1z" />
                                </g>
                            </g>
                            <path className="st3" d="M1087.2,598.5c4.1,3.2,9.4,7.6,15,13.3c1.6,1.6,5.7,5.8,9.8,11c6.4,8,10.5,15.6,15,24.9
                               c5.8,11.9,11.6,23.8,17.4,41.6c4.9,15,12.4,38.6,13.3,70c0.4,12.4-0.4,22.8-1.2,30.1c-4.1-4.7-8.2-7.8-11.6-9.8
                               c-19.1-11.7-42.3-7.5-64.2-3.5c-8.2,1.5-15,3.3-19.7,4.6c1.2-11,2.3-22,3.5-33c1-9.2,1.9-18.5,2.9-27.8c-1.9-3.3-4.5-8-6.9-13.9
                               c-4.7-11.3-14.1-33.5-8.7-58.4C1058.2,619,1080.7,602.8,1087.2,598.5z" />
                            <g>
                                <g>
                                    <path className="st2" d="M1090.7,604c10,5.2,16.7,14.2,22.5,23.5c6,9.6,11.4,19.5,16.2,29.7c9.8,20.8,17,42.8,21.7,65.3
                                               c0.4,1.9,3.3,1.1,2.9-0.8c-4.8-23.4-12.4-46.1-22.8-67.7c-5.1-10.6-10.7-20.9-17.1-30.8c-5.7-8.8-12.4-17-21.8-21.9
                                               C1090.5,600.5,1089,603.1,1090.7,604L1090.7,604z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1059.5,705.7c4.6,9.4,9.4,18.6,14.4,27.8c0.9,1.7,3.5,0.2,2.6-1.5c-5-9.2-9.8-18.4-14.4-27.8
                                               C1061.2,702.4,1058.6,703.9,1059.5,705.7L1059.5,705.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M1066.6,722.9c-2.5,19.4-5,38.8-7.4,58.2c-0.1,1,1.1,1.7,1.9,1.4c17-5.4,34.7-8,52.5-7.4
                                               c14.1,0.5,32.8,1.8,40.7,15.6c1,1.7,3.5,0.2,2.6-1.5c-7.5-13.2-23.9-15.9-37.7-16.8c-19.9-1.4-39.9,1.2-58.8,7.2
                                               c0.6,0.5,1.3,1,1.9,1.4c2.5-19.4,5-38.8,7.4-58.2C1069.9,721,1066.9,721,1066.6,722.9L1066.6,722.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1150.9,721c3.5,22,9.2,45.3,3.3,67.4c-0.5,1.9,2.4,2.7,2.9,0.8c6-22.6,0.3-46.4-3.3-69
                                               C1153.5,718.3,1150.6,719.1,1150.9,721L1150.9,721z" />
                                </g>
                            </g>
                            <path className="st12"
                                d="M1079.6,594.8c9.1,17,18.2,37.3,25.9,60.6c15.1,46,19.1,87.3,19.4,118.2c-10.2,0.6-20.4,1.2-30.6,1.8
                               c3.3,23.7,6.7,47.5,10,71.2c-6.4-3.7-16.2-8.4-28.8-11.2c-4.1-0.9-19.2-3.9-35.3-1.2c-33.8,5.7-54.2,33.4-58.8,40
                               c4.6,1.9,10.5,3.7,17.6,4.1c21.8,1.3,37.7-11.3,42.4-15.3c14.9,7.5,27.9,11.1,37.1,12.9c16.1,3.3,34.9,7.1,45.3-2.9
                               c0.8-0.8,2.6-2.7,6.5-12.4c6.1-15.4,9.3-29.8,13.5-55.9c0.9-5.8,2.2-13.6,3.5-22.9c2.5,2.5,5.1,5.1,7.6,7.6
                               c1.2-4.5,2.7-11.2,3.5-19.4c2.1-21.3-2-38.1-5.9-51.8c-15.7-55.4-25.1-88.8-56.5-113C1089.5,600.4,1083.5,596.9,1079.6,594.8z" />
                            <path className="st13" d="M1132.5,663.3c6.7,32.6,7.6,60.3,7.1,80.3c-0.5,18.6-2.4,32.2-4.4,45.9c-5.9,41.3-8.9,61.9-20.3,71.5
                               c-21.6,18.2-60.1,4.4-69.3,0.9c8.7,5.8,40,25.5,70.6,14.6c3.2-1.2,6.7-2.7,10.1-4.9c3.6-6.1,8.4-15.5,11.5-27.8
                               c1.8-7.3,2.3-12.6,3-18.8c1.1-10,3-24.6,6.2-42.9c2.9,2.5,5.9,5,8.8,7.5c1-5.1,2.3-12.9,2.6-22.5c0.9-23.2-4.2-40.8-10.1-60.9
                               C1145.1,695.2,1140.1,680.4,1132.5,663.3z" />
                        </g>
                        <g id="head">
                            <path className="st14"
                                d="M857.6,400.8c1,10.7,2.8,25.7,6.8,43.4c2.6,11.5,4.2,18.8,7.6,27.9c5.1,13.7,14.8,39.9,40.1,57
                               c6.1,4.2,16.2,9.8,30.4,13.1c2.3,0.2,4.6,0.5,6.9,0.7c0.8,4.2,1.8,10.4,2.8,17.9c2.4,19.3,1.2,25.2,6.2,30.4
                               c6.1,6.3,15.8,5.7,17.9,5.5c16.7-1.1,25.1-17.7,25.5-18.6c-2.7-4.9-5.4-10.9-7.6-17.9c-4.8-15.5-4.9-29.3-4.1-38.7
                               c7.7-11.1,16.2-19.6,24.8-26.2c3.3-2.5,6.1-5.9,11-7.6c7-2.4,10.6,0,15.8-1.6c8.6-2.7,12.3-13.8,13.9-18.4
                               c6.1-18.2-1.4-36.2-6.2-46.9c-4.3-9.8-15.5-34.1-42.8-53.1c-28.1-19.6-84.3-41.4-121.5-13.1C878.7,359.5,864.1,372.6,857.6,400.8z" />
                            <path className="st8"
                                d="M861.8,442c-6.4-1.1-22.6-4.9-33.1-18.8c-16-21.1-7.4-50.6,1.5-66.2c14-24.5,39.5-33,71.5-43.7
                               c19-6.3,37.1-12.3,61.7-11.3c13.6,0.6,59.7,2.6,87.3,36.9c16.4,20.3,18.2,42.1,21.8,85.1c1.7,19.7,0.4,30.4-3,40.6
                               c-2.5,7.4-10.6,31.1-33.9,40.6c-12.2,5-23.5,4-29.7,3c5.6-8.3,11.2-16.5,16.9-24.8c3.2,0.2,14.6,0.4,23.3-7.5
                               c11.9-10.9,14-32.6,5.3-38.4c-6.4-4.3-19.1-0.1-30.1,9.8c-1-6.3-2.6-13.2-5.3-20.3c-12.6-33.9-40-52.5-53.4-60.2
                               c-5.3,6.8-22.1,26.7-51.9,34.6c-23.6,6.2-43.2,1.8-51.9-0.8C859.8,414.4,860.8,428.2,861.8,442z" />
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M856.8,403.4c4.1,30.9,8.4,63,23.5,90.8c12.4,22.8,32.4,42.3,58.1,48.8c1.9,0.5,2.7-2.4,0.8-2.9
                                               c-25.4-6.5-45-26.1-57-48.8c-14.3-27-18.4-58-22.4-87.9C859.5,401.5,856.5,401.5,856.8,403.4L856.8,403.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M938.4,543.4c18.1,0,37.1-9.7,51.3-20.1c14.3-10.5,26-24,34.4-39.6c0.9-1.7-1.7-3.2-2.6-1.5
                                               c-8.1,15.2-19.4,28.4-33.3,38.5c-13.9,10.2-32.3,19.7-49.8,19.7C936.4,540.4,936.4,543.4,938.4,543.4L938.4,543.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M989.6,520.9c-2,20.2,1.9,40.4,11.1,58.5c0.9,1.7,3.5,0.2,2.6-1.5c-9-17.7-12.6-37.3-10.7-57
                                               C992.8,519,989.8,519,989.6,520.9L989.6,520.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M946.1,542.9c3.3,11.4,6,23,8,34.7c1.3,7.8,1.8,16.6,10.5,19.5c13,4.4,32.2-3.4,37.2-16.3
                                               c0.7-1.8-2.2-2.6-2.9-0.8c-2.8,7.3-9.8,11.3-17,13.4c-6,1.7-15.1,3.5-20.2-1.4c-3-2.9-3.4-7.5-4-11.4c-0.7-4.1-1.4-8.2-2.2-12.3
                                               c-1.8-8.8-3.9-17.6-6.4-26.2C948.5,540.3,945.6,541.1,946.1,542.9L946.1,542.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M950.4,555.3c17.8-2.3,29.5-15.9,38.1-30.5c1-1.7-1.6-3.2-2.6-1.5c-7.9,13.6-18.9,26.9-35.5,29
                                               C948.5,552.6,948.5,555.6,950.4,555.3L950.4,555.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M949.7,545.1c13.9-1.6,26.4-8.1,35.7-18.6c1.3-1.4-0.8-3.6-2.1-2.1c-8.7,9.9-20.5,16.2-33.6,17.7
                                               C947.9,542.3,947.8,545.3,949.7,545.1L949.7,545.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M950.2,542.9c11.2-1.6,21.3-6.2,29.9-13.5c1.5-1.3-0.7-3.4-2.1-2.1c-8.2,7-17.9,11.3-28.6,12.8
                                               C947.5,540.3,948.3,543.2,950.2,542.9L950.2,542.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M949.6,545.4c0.5,2.4,0.4,7.2,3.4,8c2.9,0.8,7.3-2.6,9.6-4c7.9-4.8,14.5-11.3,19.7-18.9
                                               c1.1-1.6-1.5-3.1-2.6-1.5c-3.7,5.5-8.2,10.4-13.5,14.4c-2.5,1.9-5.1,3.5-7.8,5c-1.1,0.6-3.6,2.3-4.7,1.6c-0.7-0.4-1-4.7-1.1-5.4
                                               C952.1,542.7,949.2,543.5,949.6,545.4L949.6,545.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M951,548.2c8.6-3.5,16.7-7.9,24.3-13.2c1.6-1.1,0.1-3.7-1.5-2.6c-7.4,5.2-15.2,9.5-23.6,12.9
                                               C948.4,546,949.2,548.9,951,548.2L951,548.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M952.5,551.2c9.6-3.4,18-8.7,25-16.1c1.3-1.4-0.8-3.5-2.1-2.1c-6.7,6.9-14.6,12.1-23.7,15.3
                                               C949.9,549,950.7,551.9,952.5,551.2L952.5,551.2z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M954.1,549.6c7.1-3.1,13.6-7.3,19.2-12.7c1.4-1.3-0.7-3.4-2.1-2.1c-5.5,5.2-11.7,9.2-18.6,12.2
                                               C950.8,547.8,952.3,550.4,954.1,549.6L954.1,549.6z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M872.4,479.4c-16.3-5.8-21.7-27.1-20.1-42.5c1.7-16.9,16.9-35.3,35.5-27.4c8.9,3.8,12.2,13.6,14.6,22.3
                                               c2.5,9.2,3.6,19.4,1.2,28.8c-2.1,8.3-7.2,16.2-15.2,19.8c-8,3.6-17.5,1.6-22.9-5.3c-1.2-1.5-3.3,0.6-2.1,2.1
                                               c9.1,11.7,26.2,9.4,35.4-0.9c11.7-13.1,10.5-33,5.5-48.7c-2.3-7.3-5.3-14.9-12-19.1c-5.8-3.7-13.2-4.6-19.7-2.9
                                               c-15.2,4.1-23.1,20.2-23.4,34.9c-0.3,16.1,6,36,22.6,41.8C873.4,482.9,874.2,480,872.4,479.4L872.4,479.4z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M907,449.5c9.8-5.9,21.7-6.5,31.9-1.4c1.7,0.9,3.2-1.7,1.5-2.6c-11.2-5.6-24.2-5.1-35,1.4
                                               C903.9,447.9,905.4,450.5,907,449.5L907,449.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M954.8,470.8c-11.3-10.9-16-27.8-11.6-43c4.6-15.8,20.4-29.4,37.4-23.8c18.8,6.2,19.8,33.1,14.9,48.9
                                               c-2.9,9.3-8.7,18.6-18.3,22c-9.6,3.4-17.9-1.7-25.6-7.1c-1.6-1.1-3.1,1.5-1.5,2.6c6.3,4.5,13.1,9,21.1,8.7
                                               c7.6-0.3,14.5-4.5,19.2-10.2c10.6-12.8,12.7-32.3,8.3-47.9c-4-14.2-15.8-23.4-30.9-20.9c-14.4,2.3-25,15.3-28.2,28.9
                                               c-3.7,15.7,1.3,32.7,12.9,43.9C954.1,474.3,956.2,472.1,954.8,470.8L954.8,470.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M881.5,437.9c-1,6.3,0.1,12.4,3.5,17.8c0.7,1.1,2.3,0.9,2.7-0.4c2.4-6.9-1-13.7-4.4-19.6
                                               c-0.7-1.3-2.8-0.7-2.8,0.8c0.1,5.3,1.2,10.5,3.2,15.4c0.7,1.8,3.6,1,2.9-0.8c-1.9-4.7-3-9.5-3.1-14.6c-0.9,0.3-1.9,0.5-2.8,0.8
                                               c3.1,5.3,6.3,11.1,4.1,17.3c0.9-0.1,1.8-0.2,2.7-0.4c-3-4.7-4.1-10-3.2-15.5C884.7,436.8,881.8,436,881.5,437.9L881.5,437.9z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2"
                                        d="M970,435.1c0-1.3-0.1-1.6-0.7-2.7c-0.7-1.3-2.8-0.7-2.8,0.8c0.1,6.2,0.6,12.9,5.4,17.3
                                               c1,0.9,2.4,0.2,2.6-1.1c0.8-7.4-1.4-14.3-6-20.1c-1-1.2-2.8,0.1-2.5,1.5c1.2,4.9,2.7,9.7,4.5,14.3c0.7,1.8,3.6,1,2.9-0.8
                                               c-1.9-4.7-3.4-9.4-4.5-14.3c-0.8,0.5-1.7,1-2.5,1.5c4.1,5.2,5.8,11.4,5.1,18c0.9-0.4,1.7-0.7,2.6-1.1c-4.3-3.9-4.5-9.8-4.6-15.2
                                               c-0.9,0.3-1.9,0.5-2.8,0.8c0.1,0.4,0.2,0.8,0.3,1.2C967,437,970,437,970,435.1L970,435.1z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M917,454.8c-2,6-3.7,12.1-4.8,18.3c-0.6,3.3-2.7,9.5-1.2,12.8c3,6.7,16.3,2.6,20.9,0.4
                                               c1.7-0.8,0.2-3.4-1.5-2.6c-3.7,1.8-7.7,2.7-11.8,2.4c-2.4-0.2-4.3-0.6-4.8-3.3c-0.3-1.5,0.7-5.4,1-7.1c1.2-6.9,3-13.6,5.2-20.2
                                               C920.5,453.8,917.6,453,917,454.8L917,454.8z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M932.1,512.5c7.5-1,14-5.8,17.3-12.7c0.8-1.7-1.8-3.3-2.6-1.5c-3,6.3-8.6,10.4-15.5,11.3
                                               C929.4,509.9,930.3,512.8,932.1,512.5L932.1,512.5z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M875.2,429.3c1.9-2.3,5.4-2.7,7.8-0.7c1.5,1.3,3.6-0.9,2.1-2.1c-3.5-3-8.9-2.8-12,0.7
                                               C871.8,428.6,873.9,430.7,875.2,429.3L875.2,429.3z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M961,423.7c3.1-2.7,7.8-2.7,10.9-0.1c1.5,1.2,3.6-0.9,2.1-2.1c-4.4-3.8-10.8-3.7-15.2,0.1
                                               C957.4,422.9,959.6,425,961,423.7L961,423.7z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st2" d="M1000.4,437.5c10.8-3.8,21.9-6.1,33.4-6.7c1.9-0.1,1.9-3.1,0-3c-11.7,0.6-23.1,2.9-34.2,6.8
                                               C997.8,435.3,998.5,438.2,1000.4,437.5L1000.4,437.5z" />
                                </g>
                            </g>
                            <path className="st4"
                                d="M1015.3,312.9c6,7.7,29.1,39.3,24.8,82.7c-2.4,24.2-12.4,42-18.7,51.4c3.6-2.8,6.7-4.7,9-6
                               c6.1-3.4,10-5.5,15-5.2c1.5,0.1,5.1,0.4,7.7,2.5c7.6,6.4,5,29.1-8.5,39.7c-7.4,5.8-16.1,6.4-20.5,6.3c-5.7,8.1-11.5,16.2-17.2,24.4
                               c3.8,0.6,9.6,1.1,16.3,0.1c2.9-0.4,14.5-2.5,25.5-11.3c10.8-8.7,15.2-19.3,18.1-26.5c8-20,6.4-37.6,3.6-69.3
                               c-1.2-14.1-1.9-21.3-4.7-30.8c-3-10.3-7.7-25.5-21.1-39.1C1035,321.8,1024.2,316.2,1015.3,312.9z" />
                            <path className="st15" d="M918.7,398.4c10-8.9,21.2-20.3,32-34.5c6.7-8.8,12.3-17.3,16.9-25.2c-1.7,9-3.4,18-5,27
                               c-3.9,5.6-10.4,13.6-20.5,20.9C933.2,392.9,924.8,396.4,918.7,398.4z" />
                            <path className="st16"
                                d="M989.8,387c7.6,25.3,8.2,46.5,7.5,60.5c-0.5,10.4-3.3,64.9-32,83.7c-2.8,1.8-9.9,6-9.6,6.6
                               c0.4,0.7,13.7-5.2,21-9.2c11.5-6.2,29.2-18.7,45.6-45.1c2.7,0.3,15.9,1.2,25.4-7.9c11.5-10.9,13.5-32.3,4.4-38.6
                               c-6.5-4.5-18.9-0.7-29.8,8.3c-1.1-4.6-2.5-9.4-4.4-14.5C1010.4,411,999.1,396.6,989.8,387z" />
                            <path className="st17"
                                d="M965.7,537c2.6,8,5.2,16,7.8,24.1c3.6,11,7.1,22.1,10.6,33.1c2-0.4,6.9-1.8,11.2-6.2s5.7-9.2,6.2-11.2
                               c-3.9-7.7-8.5-19.4-10.1-34.2c-0.8-7.7-0.6-14.5,0-20.2C982.9,527.3,974.3,532.2,965.7,537z" />
                            <path className="st18" d="M915.8,458.3c-5,10.7-10,21.3-15,32c8.2-0.2,16.3-0.3,24.5-0.5c-4.8-1.5-9.7-3-14.5-4.5
                               C912.4,476.3,914.1,467.3,915.8,458.3z" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>


    )

}
