import React, { useState } from "react";
import "./About.scss";
import environment from "../../Environment/Environment";
import { motion } from "framer-motion";
import MetaDecorator from "../../utils/MetaTag/MetaDecorator";
import tags from "../../utils/MetaTag/MetaTags";
export default function About() {
  var details = [
    [
      "Who Are We",
      "Pennonn is an emerging Software Development company based in New York and founded by a group of experienced IT professionals in 2020. We serve globally. We create innovative web and mobile applications that enable our customers to compete and win. We refine strategies to empower business with new technologies and with a dedicated team of developers we bring life to your ideas. We believe in a Data-Driven approach to provide best solutions and decisions to our customers. We excel in delivering a viable outcome with speed.",
    ],
    [
      " What Do We Offer",
      "We offer a wide range of services such as Product Engineering, DevOps Transformation, Agile Transformation, Cloud Computing and Cloud Migration and CTO As A Service. We offer Quality Engineering, Mobile Solutions, App Transformation, Security Transformation and Engineering Automation. We are committed to create opportunities that lead to a sustainable world and future. We focus on Customer Satisfaction. We work together as a team to deliver exceptional experience to our Customers. We value our Clients and deliver high quality digital solutions and technology that can transform their business.",
    ],
    [
      "Why Choose Pennonn",
      "We find digital solutions to Businesses by examining and analyzing the data and by deploying innovative strategies and executing the plans. Delivering a customized and smarter solution for each Business with efficiency, higher quality outputs, decreased costs and elevated customer satisfaction with scalable technology is our promise. With Lean and Agile Project portfolio management we help in a complete Digital Transformation with reduced organizational risks. We create opportunities for industries and business by harnessing the power of cloud computing and providing enhanced security, scalability, business agility and operational excellence. With customized software we help you improve and automate workflows. We help organizations with complete digital transformation at affordable costs.",
    ],
    // [
    //   "Who Are We",
    //   "We are a team of dedicated software professionals who are passionate about creating innovative digital solutions that help businesses thrive in today's fast-paced digital landscape. We believe that technology should not only make our lives easier, but also make the world a better place.",
    //   "Our expertise lies in product engineering, DevOps and Agile transformations, cloud computing and migration, CTO as a Service, quality engineering, mobile solutions, app transformation, security transformation, and engineering automation. We work closely with our clients to understand their unique challenges and tailor our services to meet their specific needs.",
    //   "At pennonn, we believe in the power of collaboration and teamwork. Our team of experts work closely together to deliver exceptional results for our clients. We believe in staying up-to-date with the latest trends and technologies in the industry, and are committed to continuous learning and improvement.",
    //   "We pride ourselves on our commitment to customer satisfaction, and work tirelessly to ensure that our clients are happy with the solutions we provide. If you're looking for a team of dedicated professionals who can help take your business to the next level, look no further than pennonn. Contact us today to learn more about how we can help you achieve your goals.",
    // ],
    // [
    //   "What Do We Offer",
    //   "Pennonn offers an innovative and creative approach to creating digital products that provides a unique and memorable experience. Our team of experienced professionals have the knowledge and skillset to develop your brand and create a product that stands out from the crowd. We have already worked with hundreds of clients in different industries and achieved amazing results",
    //   "Mobile apps: Mobile apps are a great way to reach customers on-the-go and provide a unique user experience. Consider creating a mobile app that solves a specific problem or provides a new way for customers to interact with your brand.",
    //   "Interactive websites: Interactive websites are a great way to engage customers and keep them on your website for longer. Consider creating a website that includes interactive elements such as animations, games, or quizzes.",
    //   "E-learning platforms: E-learning platforms are a great way to provide educational content to customers and position your brand as a thought leader in your industry. Consider creating an e- learning platform that offers courses, tutorials, or webinars on topics related to your products or services.",
    //   "Chatbots: Chatbots can provide customers with quick and personalized support, and can be integrated into your website or mobile app. Consider creating a chatbot that can answer common customer questions or provide product recommendations.",
    // ],
    // [
    //   "Why Choose Pennonn",
    //   "We find digital solutions to Businesses by examining and analyzing the data and by deploying innovative strategies and executing the plans. Delivering a customized and smarter solution for each Business with efficiency, higher quality outputs, decreased costs and elevated customer satisfaction with scalable technology is our promise. With Lean and Agile Project portfolio management we help in a complete Digital Transformation with reduced organizational risks. We create opportunities for industries and business by harnessing the power of cloud computing and providing enhanced security, scalability, business agility and operational excellence. With customized software we help you improve and automate workflows. We help organizations with complete digital transformation at affordable costs.",
    // ],
  ];
  const [aboutdetails, setaboutdetails] = useState(details[0]);
  const [view, setview] = useState(false);
  const [whoactive, setwhoactive] = useState(true);
  const [whatactive, setwhatactive] = useState(false);
  const [whyactive, setwhyactive] = useState(false);

  function activate(item) {
    if (item == "who") {
      setwhoactive(true);
      setwhatactive(false);
      setwhyactive(false);
    } else if (item == "what") {
      setwhoactive(false);
      setwhatactive(true);
      setwhyactive(false);
    } else {
      setwhoactive(false);
      setwhatactive(false);
      setwhyactive(true);
    }
  }
  return (
    <div className="about-mainsection">
      <MetaDecorator
        title={tags.about.title}
        description={tags.about.description}
        keywords={tags.about.keywords}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeIn" }}
        className="about-headsection"
      >
        <div className="abouthead">
          <h2>ABOUT US</h2>
          <hr className="headingUnderline"></hr>
        </div>

        <p className="topText">
        We are a team of dedicated software professionals who are passionate about creating innovative digital solutions that help businesses thrive in today's fast-paced digital landscape. We believe that technology should not only make our lives easier, but also make the world a better place.<br/><br/>
        Our expertise lies in product engineering, DevOps and Agile transformations, cloud computing and migration, CTO as a Service, quality engineering, mobile solutions, app transformation, security transformation, and engineering automation. We work closely with our clients to understand their unique challenges and tailor our services to meet their specific needs.<br/><br/>
        At pennonn, we believe in the power of collaboration and teamwork. Our team of experts work closely together to deliver exceptional results for our clients. We believe in staying up-to-date with the latest trends and technologies in the industry, and are committed to continuous learning and improvement.<br/><br/>
        We pride ourselves on our commitment to customer satisfaction, and work tirelessly to ensure that our clients are happy with the solutions we provide. If you're looking for a team of dedicated professionals who can help take your business to the next level, look no further than pennonn. Contact us today to learn more about how we can help you achieve your goals.

        </p>

        <div className="aboutbody-section">
          <div className="about-Ws">
            <div
              className={`Wgroup ${whoactive ? `active` : null}`}
              onClick={() => {
                setaboutdetails(details[0]);
                setview(true);
                activate("who");
              }}
            >
              <img
                src={environment.mediaUrl + "/aboutIcons/who.png"}
                alt=""
                className="image"
              />
              <h4>Who</h4>
            </div>
            <div
              className={`Wgroup ${whatactive ? `active` : null} `}
              onClick={() => {
                setaboutdetails(details[1]);
                setview(true);
                activate("what");
              }}
            >
              <img
                src={environment.mediaUrl + "/aboutIcons/what.png"}
                alt=""
                className="image"
              />
              <h4>What</h4>
            </div>
            <div
              className={`Wgroup  ${whyactive ? `active` : null} `}
              onClick={() => {
                setaboutdetails(details[2]);
                setview(true);
                activate("why");
              }}
            >
              <img
                src={environment.mediaUrl + "/aboutIcons/why.png"}
                alt=""
                className="image"
              />
              <h4>Why</h4>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.8, ease: "easeIn" }}
            onAnimationEnd={() => {
              setview(false);
            }}
            className={`aboutbody-details ${view ? "viewaboutbody" : ""}`}
          >
            <h3>{aboutdetails[0]}</h3>
            <p>{aboutdetails[1]}</p>
            <p>{aboutdetails[2]}</p>
            <p>{aboutdetails[3]}</p>
            <p>{aboutdetails[4]}</p>
            <p>{aboutdetails[5]}</p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
