import React, { useState } from "react";
import "./Industries.scss";
import environment from "../../Environment/Environment";
import { motion } from "framer-motion";
import MetaDecorator from "../../utils/MetaTag/MetaDecorator";
import tags from "../../utils/MetaTag/MetaTags";
export default function Industries() {
  return (
    <div className="about-mainsection">
      {/* <img
         className="imgTop"
          src="https://img.freepik.com/free-vector/professional-programmers-configuring-software-colorful-flat-illustration_1284-60230.jpg?w=1060&t=st=1678340258~exp=1678340858~hmac=dbd78db193a606dd60c74c5a4cbd740ab338970b84a06fbb579cc8f838abf45c"
          alt=""
        /> */}
      <MetaDecorator
        title={tags.about.title}
        description={tags.about.description}
        keywords={tags.about.keywords}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeIn" }}
        className="industy-headsection"
        // className="about-headsection"
      >
        <div className="abouthead">
          <h2>INDUSTRIES</h2>
          <hr className="headingUnderline"></hr>
        </div>

        <p className="topText">
          Pennonn offers an innovative and creative approach to creating digital
          products that provides a unique and memorable experience. Our team of
          experienced professionals have the knowledge and skillset to develop
          your brand and create a product that stands out from the crowd. We
          have already worked with hundreds of clients in different industries
          and achieved amazing results
          <br />
          <br />
          <b> Mobile apps:</b> Mobile apps are a great way to reach customers
          on-the-go and provide a unique user experience. Consider creating a
          mobile app that solves a specific problem or provides a new way for
          customers to interact with your brand.
          <br />
          <br />
          <b> Interactive websites: </b> Interactive websites are a great way to
          engage customers and keep them on your website for longer. Consider
          creating a website that includes interactive elements such as
          animations, games, or quizzes.
          <br />
          <br />
          <b>E-learning platforms:</b> E-learning platforms are a great way to
          provide educational content to customers and position your brand as a
          thought leader in your industry. Consider creating an e-learning
          platform that offers courses, tutorials, or webinars on topics related
          to your products or services.
          <br />
          <br />
          <b>Chatbots:</b> Chatbots can provide customers with quick and
          personalized support, and can be integrated into your website or
          mobile app. Consider creating a chatbot that can answer common
          customer questions or provide product recommendations.
        </p>
      </motion.div>
    </div>
  );
}
