import React, { useEffect, useState } from 'react';
import './Cases.scss'
import values from './CasesValues'
import environment from '../../../Environment/Environment';
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';
export default function Cases(props) {
    const [view, setview] = useState(false);
    return (
        <div className='caseStudy' style={{ backgroundColor: values[props.array].color }}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: .8, ease: "easeIn" }}
                onAnimationEnd={() => { setview(false) }}
                className={`caseHeadSection ${view ? "view" : ""}`} >
                <div className="logo" >

                    <img src={environment.mediaUrl + values[props.array].logo} alt="" />
                </div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: .8, ease: "easeIn" }}
                className={`casebodySection py-4 ${view ? "viewBody" : ""}`}>
                <div className="casedetails">
                    <div className='caseService'>
                        <h4>SERVICES</h4>
                        <p>{values[props.array].services}</p>
                    </div>
                    <div className="coreTech">
                        <h4>CORE TECHNOLOGIES</h4>
                        <p>{values[props.array].core}</p>
                    </div>
                    <div className="problem">
                        <h4>PROBLEM</h4>
                        <p>{values[props.array].problem}</p>
                    </div>
                    <div className="coreTech">
                        <h4>SOLUTION</h4>
                        {
                            values[props.array].solution.map((i) => {
                                return (<p>{i}</p>)
                            })
                        }

                    </div>
                </div>
                <div className="prototype">
                    <img src={environment.mediaUrl + values[props.array].prototype} alt="" />
                </div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: .8, ease: "easeIn" }}
                className={`pagination ${view ? "viewpagination" : ""}`}>
                {props.array != 0 && <Link to="/projects/casestudies"><button onClick={() => { props.setarray(props.array - 1); setview(true) }}>Previous</button></Link>}
                <Link to="/projects"><button>Go Back</button></Link>
                {props.array != 3 && <Link to="/projects/casestudies"><button onClick={() => { props.setarray(props.array + 1); setview(true) }}>Next</button></Link>}
            </motion.div>

        </div>
    );
}
