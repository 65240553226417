 const values = [
    {
        color:"#e8a8aa",
        logo: "/projectLogos/wellcarelogo.png",
        services: "Product discovery, Prototyping, UX/UI design, Frontend and Backend development",
        core: "Angular, AWS",
        problem: "A UK based company needs an online situation for marketing their specialized nursing care service across the Europe",
        prototype: "/home/projectImg.png",
        solution: ["Create a fast-loading web application.", "Timesheet tracking of nursing care staff",
            "Download job application formBuilt a cross-platform progressive web application that provides users with enhanced mobile experience.",
            "The app is cross platform and provides a seamless experience for both Android and iPhone users."
        ],

    },
    {
        color:"#eac492",
        logo: "/projectLogos/byrebarnlogo.png",
        services: "Product discovery, Prototyping, UX/UI design, Frontend and Backend development, MVP development",
        core: "Angular, .net core, AWS",
        problem: "Creating a centralized solution for Livestock auction.",
        prototype: "/projectLogos/byrebarnimg.png",
        solution: ["Delivered animal selling and auction MVP under budget, which was crucial for a bootstrapping company. Users can search simple advanced searches for animals.",
            "Integrated merchant account for sellers. Google Distance Matrix API, One of the great features of the animal search, users can see the animals from nearby Location with distance indicators. To build this functionality, we used Google Distance Matrix API.",
            "Seller and buyer can communicate through an anonymous messaging system.Integrated payment gateway.",
            "Built a cross-platform progressive web application that provides users with enhanced mobile experience.The app is cross platform and provides a seamless experience for both Android and iPhone users."
        ],

    },
    {
        color:"#8a9683",
        logo: "/projectLogos/tripmochalogo.png",
        services: "UX/UI design, Front-end, Back-end, MVP development",
        core: "Angular, .net core, AWS",
        problem: "Incredible India and Australia tourism ministry want to jointly collaborate for a solution to improve the travel and hospitality market between India and Australia. They want a portal to cater different offering like tourist attraction, hotel, etc to Incredible India program.",
        prototype: "/projectLogos/tripmochaimg.png",
        solution: ["Build a minimum viable product of a Tourist attractions, accommodation, restaurant picking platform in a short time frame.",
            "Allow the users to login and manage their blogs and content.",
            "Provide a feature to write blogs about visited place.",
            "Create an admin panel for blog approval process.",
            "Create a fast-loading house rent website for expats to find the most suitable accommodation that meets specific requirements.",
            "Find the most convenient way to collect search criteria from users to offer them suitable search results based on their location.",
            "To ensure high website performance, we used AWS CDN to pull the images based on the region."
        ],

    },
    {
        color:"#cec4c4",
        logo: "/projectLogos/shipexLogo.png",
        services: "Product discovery, Prototyping, UX/UI design, Frontend and Backend development",
        core: "Angular, .net core, AWS",
        problem: "Enhance and migrate the current on-premises IT solution for a third party logistics company to cloud.",
        prototype: "/projectLogos/shipeximg.png",
        solution: ["Provided a solution which includes infrastructure, platform and data migration.",
            "To ensure high website performance, we used AWS CDN to pull the images based on the region.",
            "Built a cross-platform progressive web application that provides users with enhanced mobile experience.",
            "The app is cross platform and provides a seamless experience for both Android and iPhone users."
        ],

    },
]
export default values;