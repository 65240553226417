import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../utils/language/langageSettings';
import { useSelector } from 'react-redux';
import './App.scss';
import '../index.css'
import Home from '../components/Home/Home';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Form from '../components/Contact/Contact';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop';
import { useEffect, useState } from 'react';
import Services from '../components/ServicesSection/Services';
import About from '../components/AboutSection/About';
import Career from '../components/CareersSection/Career';
import Project from '../components/ProjectSection/Project';
import Cases from '../components/ProjectSection/Cases/Cases';
import Industries from '../components/IndustriesSection/Industries';
import Sitemap from '../components/Sitemap/Sitemap';
function App() {
  const lan = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabi" },
    { code: "es", name: "	Spanish" },
  ]

  const { t } = useTranslation();
  const lang = useSelector(store => store.lang);

  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
  }

  const [isLoading, setLoading] = useState(true);
  const [arrays, setarray] = useState(0);

  function fakeRequest() {
    return new Promise(resolve => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove();
        setLoading(!isLoading);
      }
    });
  }, []);

  // if (isLoading) {
  //   return null;
  // }
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ScrollToTop >
          <Routes>
            <Route path="/home" element={<Home />} >
            </Route>
            <Route path="/contactus" element={<Form />}>
            </Route>
            <Route path="/services" element={<Services />}>
            </Route>
            <Route path="/about" element={<About />}>
            </Route>
            <Route path="/careers" element={<Career />}>
            </Route>
            <Route path="/projects" element={<Project setarray={setarray} />}>
            </Route>
            <Route path="/industries" element={<Industries />}>
            </Route>
            <Route path="/projects/casestudies" element={<Cases array={arrays} setarray={setarray} />}>
            </Route>
            <Route path="/sitemap" element={<Sitemap />}>
            </Route>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="*" element={<Navigate replace to="/home" />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
      {/* <p>hello</p>
      <p>hello</p>
      <p>hello</p>
      <p>hello</p> */}
    </div>
  );
}

export default App;
