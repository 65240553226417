import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector"
//import all languages here
import en from "../assets/languages/en.json"
import ar from "../assets/languages/ar.json"
import es from "../assets/languages/es.json"

const resources = {
    en,
    ar, 
    es
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: {
            order: ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage'],
        },
        fallbackLng: "en",
        ns: ["translation"],
        defaultNS: "translation",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
        }
    });



export default i18n;