import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import store from "./store";
import { Provider } from "react-redux";
import App from "./app/App";
import './localization/i18n'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/Poppins-Regular.ttf'
import './assets/fonts/Poppins-Bold.ttf'
import './assets/fonts/Poppins-Light.ttf'
import './assets/fonts/Poppins-Medium.ttf'
import './assets/fonts/Poppins-SemiBold.ttf'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Suspense fallback={<div className="">Loading...</div>}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  // </React.StrictMode>
);

